import { Link } from "react-router-dom";

export function Footer() {
  return (
    <footer className="border-t bg-white/95 backdrop-blur supports-[backdrop-filter]:bg-white/60">
      <div className="container mx-auto px-4 py-6">
        <div className="flex flex-col md:flex-row justify-between items-center gap-4">
          <div className="text-sm text-gray-600">
            © {new Date().getFullYear()} MemorySpace.co. All rights reserved.
          </div>
          <div className="flex flex-col md:flex-row items-center gap-4">
            <Link to="/terms" className="text-sm text-gray-600 hover:text-gray-900">
              Terms & Conditions
            </Link>
            <Link to="/privacy" className="text-sm text-gray-600 hover:text-gray-900">
              Privacy Policy
            </Link>
            <a 
              href="mailto:Support@MemorySpace.co" 
              className="text-sm text-gray-600 hover:text-gray-900"
            >
              Support@MemorySpace.co
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}