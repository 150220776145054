import React from 'react';
import { Plus, FolderPlus, Search, Folder } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet';
import { Input } from '@/components/ui/input';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { SortOption } from '@/utils/memorySort';

interface MobileNavigationProps {
  onNewMemory: () => void;
  onCreateFolder: () => void;
  searchQuery: string;
  onSearchChange: (value: string) => void;
  folders: string[];
  currentFolder: string;
  onFolderChange: (folder: string) => void;
  sortOption: SortOption;
  onSortChange: (value: SortOption) => void;
}

const MobileNavigation = ({
  onNewMemory,
  onCreateFolder,
  searchQuery,
  onSearchChange,
  folders = [],
  currentFolder = '',
  onFolderChange,
  sortOption,
  onSortChange,
}: MobileNavigationProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  console.log('MobileNavigation folders:', folders);

  // Add "Favorites" to the folders list if it doesn't exist
  const allFolders = folders.includes("Favorites") ? folders : ["Favorites", ...folders];

  const handleFolderSelect = (folder: string) => {
    onFolderChange(folder);
    setIsOpen(false); // Close the dropdown after selection
  };

  const handleCreateFolder = () => {
    setIsOpen(false); // Close the dropdown before creating a new folder
    onCreateFolder();
  };

  return (
    <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 px-4 py-2 flex items-center justify-between z-40">
      <Button
        onClick={onNewMemory}
        className="bg-facebook-500 hover:bg-facebook-600 text-white"
      >
        <Plus className="h-4 w-4 mr-2" />
        Add Memory
      </Button>

      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <Button variant="outline">
            <Folder className="h-4 w-4 mr-2" />
            Folders
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent 
          align="end" 
          className="w-56 bg-white shadow-lg border border-gray-200 rounded-md z-50"
        >
          <DropdownMenuItem 
            onClick={() => handleFolderSelect('')}
            className="cursor-pointer hover:bg-gray-100"
          >
            All Memories
          </DropdownMenuItem>
          {allFolders.map((folder) => (
            <DropdownMenuItem 
              key={folder}
              onClick={() => handleFolderSelect(folder)}
              className="cursor-pointer hover:bg-gray-100"
            >
              {folder}
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem 
            onClick={handleCreateFolder}
            className="border-t border-gray-100 mt-2 pt-2 cursor-pointer hover:bg-gray-100"
          >
            <FolderPlus className="h-4 w-4 mr-2" />
            Create New Folder
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Sheet>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon">
            <Search className="h-4 w-4" />
          </Button>
        </SheetTrigger>
        <SheetContent side="bottom">
          <SheetHeader>
            <SheetTitle>Search Memories</SheetTitle>
          </SheetHeader>
          <div className="py-4">
            <Input
              placeholder="Search memories..."
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
              className="w-full"
            />
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
};

export default MobileNavigation;