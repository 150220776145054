import { createClient } from '@supabase/supabase-js';

const supabaseUrl = "https://peacoajtauvoomvanchc.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBlYWNvYWp0YXV2b29tdmFuY2hjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzUxNzY3OTgsImV4cCI6MjA1MDc1Mjc5OH0.79_EwlxI9JRcO9wPGwWXvf_G0j9a_RHKT3jVe_Jfgeo";

// Remove any trailing slashes or colons from the URL
const cleanUrl = supabaseUrl.replace(/[:\/]$/, '');

export const supabase = createClient(cleanUrl, supabaseKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
  },
});