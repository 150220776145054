import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import type { Database } from "@/integrations/supabase/types";

type LovedOne = Database['public']['Tables']['loved_ones']['Row'];
type LovedOneInsert = Database['public']['Tables']['loved_ones']['Insert'];

type LovedOneFormProps = {
  step: number;
  name: string;
  relationship: string;
  onNameChange: (value: string) => void;
  onRelationshipChange: (value: string) => void;
  onNext: () => void;
  onBack: () => void;
};

export const LovedOneForm = ({
  step,
  name,
  relationship,
  onNameChange,
  onRelationshipChange,
  onNext,
  onBack,
}: LovedOneFormProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();

  const handleNext = async () => {
    console.log('Starting handleNext with step:', step);
    
    if (step === 2 && !name) {
      toast({
        title: "Please enter their name",
        description: "This will help personalize your experience",
      });
      return;
    }
    if (step === 3 && !relationship) {
      toast({
        title: "Please enter your relationship",
        description: "This helps us understand your connection",
      });
      return;
    }

    // If this is the final step, save to Supabase first
    if (step === 3) {
      try {
        // Get current session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw sessionError;
        }

        if (!session?.user?.id) {
          console.error('No authenticated user found');
          toast({
            title: "Authentication required",
            description: "Please sign in to continue",
            variant: "destructive",
          });
          return;
        }

        // Create the loved one data object with user_id
        const lovedOneData: LovedOneInsert = {
          name,
          relationship,
          user_id: session.user.id,
          created_at: new Date().toISOString()
        };

        console.log('Attempting to insert loved one with data:', lovedOneData);

        // Insert the new loved one
        const { data, error: insertError } = await supabase
          .from('loved_ones')
          .insert([lovedOneData])
          .select('*')
          .single();

        if (insertError) {
          console.error('Failed to save loved one:', insertError);
          throw insertError;
        }

        if (!data) {
          throw new Error('No data returned from insert');
        }

        const newLovedOne = data as LovedOne;
        console.log('Successfully created loved one:', newLovedOne);
        
        // Store in localStorage
        localStorage.setItem("lovedOne", JSON.stringify({
          name,
          relationship,
          id: newLovedOne.id
        }));
        localStorage.setItem("lovedOneId", newLovedOne.id.toString());
        localStorage.setItem("conversationId", newLovedOne.id.toString());
        
        toast({
          title: "Setup Complete",
          description: "Your loved one has been saved successfully",
        });

        // Navigate to dashboard instead of calling onNext
        navigate("/dashboard");
      } catch (error: any) {
        console.error('Failed to save loved one:', error);
        toast({
          title: "Error",
          description: error?.message || "Failed to save your loved one. Please try again.",
          variant: "destructive",
        });
        return;
      }
    } else {
      // For non-final steps, just proceed
      onNext();
    }
  };

  return (
    <div className="space-y-6">
      {step === 2 && (
        <Input
          type="text"
          placeholder="Enter their name"
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
          className="input-field"
        />
      )}
      {step === 3 && (
        <Input
          type="text"
          placeholder="e.g., Parent, Sibling, Friend"
          value={relationship}
          onChange={(e) => onRelationshipChange(e.target.value)}
          className="input-field"
        />
      )}
      <div className="flex justify-between">
        <Button onClick={onBack} className="btn-secondary">
          Back
        </Button>
        <Button onClick={handleNext} className="btn-primary">
          {step === 3 ? "Complete" : "Continue"}
        </Button>
      </div>
    </div>
  );
};