import { Draggable } from 'react-beautiful-dnd';
import { Memory } from '@/types/memory';
import MemoryCard from './MemoryCard';

interface DraggableMemoryProps {
  memory: Memory;
  index: number;
  onEdit: (memory: Memory) => void;
  onDelete: (id: string) => void;
  onToggleFavorite: (id: string, isFavorite: boolean) => void;
}

const DraggableMemory = ({ memory, index, onEdit, onDelete, onToggleFavorite }: DraggableMemoryProps) => {
  return (
    <Draggable draggableId={memory.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <MemoryCard
            memory={memory}
            onEdit={onEdit}
            onDelete={onDelete}
            onToggleFavorite={onToggleFavorite}
          />
        </div>
      )}
    </Draggable>
  );
};

export default DraggableMemory;