import React, { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Navigation } from "@/components/Navigation";
import FolderDialog from "@/components/vault/FolderDialog";
import MobileNavigation from "@/components/vault/MobileNavigation";
import { Memory } from "@/types/memory";
import { DropResult } from "react-beautiful-dnd";
import { SortOption } from '@/utils/memorySort';
import VaultHeader from "@/components/vault/sections/VaultHeader";
import VaultActions from "@/components/vault/sections/VaultActions";
import VaultContent from "@/components/vault/sections/VaultContent";
import SubscriptionDialog from "@/components/SubscriptionDialog";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useSubscription } from "@/hooks/useSubscription";

const DEFAULT_FOLDERS = ["Favorites"];

const Vault = () => {
  const navigate = useNavigate();
  const [memories, setMemories] = useState<Memory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editingMemory, setEditingMemory] = useState<Memory | undefined>();
  const [currentFolder, setCurrentFolder] = useState("");
  const [folders, setFolders] = useState<string[]>(DEFAULT_FOLDERS);
  const [searchQuery, setSearchQuery] = useState("");
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const [editingFolder, setEditingFolder] = useState<string | null>(null);
  const [sortOption, setSortOption] = useState<SortOption>('dateAdded-newest');
  const [lovedOne, setLovedOne] = useState<{ id: number; name: string; relationship: string; photo_url?: string | null } | null>(null);
  const { toast } = useToast();
  const [showFavorites, setShowFavorites] = useState(false);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const [showFirstMemoryDialog, setShowFirstMemoryDialog] = useState(false);
  const [totalMemoryCount, setTotalMemoryCount] = useState(0);
  const { isSubscribed } = useSubscription(); // Add subscription hook

  useEffect(() => {
    const checkAuthAndLoadData = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Authentication required",
          description: "Please sign in to access the Memory Vault",
          variant: "destructive",
        });
        navigate("/login");
        return;
      }

      const storedLovedOneId = localStorage.getItem("lovedOneId");
      if (storedLovedOneId) {
        const { data: lovedOneData, error: lovedOneError } = await supabase
          .from('loved_ones')
          .select('*')
          .eq('id', storedLovedOneId)
          .maybeSingle();

        if (lovedOneError) {
          console.error('Error fetching loved one:', lovedOneError);
          toast({
            title: "Error",
            description: "Failed to load loved one information",
            variant: "destructive",
          });
          return;
        }

        if (lovedOneData) {
          setLovedOne(lovedOneData);
          console.log('Setting loved one:', lovedOneData);
        }
      }
    };
    
    checkAuthAndLoadData();
  }, [navigate, toast]);

  const fetchMemories = async () => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      console.log('No authenticated user found');
      return;
    }

    console.log('Fetching memories for user:', user.email);

    setIsLoading(true);
    try {
      const { data: lovedOnes, error: lovedOnesError } = await supabase
        .from('loved_ones')
        .select('id')
        .eq('user_id', user.id);

      if (lovedOnesError) {
        console.error('Error fetching loved ones:', lovedOnesError);
        throw lovedOnesError;
      }

      if (!lovedOnes?.length) {
        console.log('No loved ones found');
        setMemories([]);
        setTotalMemoryCount(0);
        return;
      }

      const lovedOneIds = lovedOnes.map(lo => lo.id);
      console.log('Fetching memories for loved one IDs:', lovedOneIds);

      const { data: memoriesData, error: memoriesError } = await supabase
        .from('memories')
        .select('*')
        .in('loved_one_id', lovedOneIds)
        .order('created_at', { ascending: false });

      if (memoriesError) {
        console.error('Error fetching memories:', memoriesError);
        throw memoriesError;
      }

      console.log('Raw memories data:', memoriesData);

      const formattedMemories = memoriesData?.map(memory => ({
        id: memory.id.toString(),
        title: memory.context || '',
        description: memory.content || '',
        date: memory.created_at,
        photoUrl: '',
        photoCaption: memory.photo_caption || '',
        folder: memory.folder || '',
        memory_month: memory.memory_month,
        memory_year: memory.memory_year,
        loved_one_id: memory.loved_one_id,
        isFavorite: memory.is_favorite || false
      })) || [];

      console.log('Formatted memories with favorites:', formattedMemories);
      setMemories(formattedMemories);
      setTotalMemoryCount(formattedMemories.length);
      
      // Update folders list while preserving existing folders
      const uniqueFolders = Array.from(
        new Set([
          ...folders, // Keep existing folders
          ...formattedMemories
            .map((m) => m.folder)
            .filter((f): f is string => !!f && !folders.includes(f)) // Only add new folders
        ])
      );
      
      console.log('Updated folders list:', uniqueFolders);
      setFolders(uniqueFolders);

    } catch (error) {
      console.error("Error in fetchMemories:", error);
      toast({
        title: "Error",
        description: "Failed to load memories. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMemories();
  }, [lovedOne?.id]);

  const filteredMemories = React.useMemo(() => {
    console.log('Vault filtering memories:', {
      currentFolder,
      totalMemories: memories.length,
      folders,
      memoriesWithFolders: memories.map(m => ({ id: m.id, folder: m.folder, isFavorite: m.isFavorite }))
    });

    if (currentFolder === "Favorites") {
      return memories.filter(m => m.isFavorite);
    }

    if (!currentFolder) {
      return memories;
    }

    return memories.filter(m => m.folder === currentFolder);
  }, [memories, currentFolder]);

  const handleSaveMemory = async (memory: Memory) => {
    try {
      console.log('Handling memory save:', memory);
      
      setShowForm(false);
      setEditingMemory(undefined);
      
      await fetchMemories();

      // Check if this was the first memory added
      const hasSeenDialog = localStorage.getItem('hasSeenFirstMemoryDialog');
      if (!hasSeenDialog) {
        console.log('First memory ever added, showing dialog');
        setShowFirstMemoryDialog(true);
      }

      toast({
        title: "Success",
        description: "Memory saved successfully!",
      });
    } catch (error) {
      console.error("Error handling memory save:", error);
      toast({
        title: "Error",
        description: "Failed to update memories list",
        variant: "destructive",
      });
    }
  };

  const handleEditMemory = (memory: Memory) => {
    setEditingMemory(memory);
    setShowForm(true);
  };

  const handleDeleteMemory = async (id: string) => {
    console.log('Deleting memory with ID:', id);
    
    try {
      const { error } = await supabase
        .from('memories')
        .delete()
        .eq('id', id);

      if (error) {
        console.error('Error deleting memory from Supabase:', error);
        toast({
          title: "Error",
          description: "Failed to delete memory. Please try again.",
          variant: "destructive",
        });
        return;
      }

      console.log('Memory successfully deleted from Supabase');
      
      // Update local state only after successful database deletion
      const updatedMemories = memories.filter(memory => memory.id !== id);
      setMemories(updatedMemories);
      setTotalMemoryCount(prev => prev - 1);
      
      toast({
        title: "Success",
        description: "Memory deleted successfully!",
      });
    } catch (error) {
      console.error("Error in handleDeleteMemory:", error);
      toast({
        title: "Error",
        description: "Failed to delete memory. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const updatedMemories = [...memories];
    const movedMemory = updatedMemories.find(m => m.id === result.draggableId);
    
    if (movedMemory) {
      movedMemory.folder = result.destination.droppableId === "ROOT" ? "" : result.destination.droppableId;
      setMemories(updatedMemories);
      localStorage.setItem("memories", JSON.stringify(updatedMemories));
      
      toast({
        title: "Success",
        description: `Memory moved to ${result.destination.droppableId || "All Memories"}`,
      });
    }
  };

  const handleToggleFavorite = async (id: string, isFavorite: boolean) => {
    console.log('Toggling favorite:', { id, isFavorite });
    
    try {
      const { error } = await supabase
        .from('memories')
        .update({ is_favorite: isFavorite })
        .eq('id', id);

      if (error) throw error;

      // Update local state to reflect the change
      const updatedMemories = memories.map(memory => {
        if (memory.id === id) {
          return { ...memory, isFavorite };
        }
        return memory;
      });
      
      setMemories(updatedMemories);
      
      toast({
        title: isFavorite ? "Added to Favorites" : "Removed from Favorites",
        description: isFavorite 
          ? "Memory has been added to your Favorites folder" 
          : "Memory has been removed from your Favorites folder",
      });
    } catch (error) {
      console.error('Error updating favorite status:', error);
      toast({
        title: "Error",
        description: "Failed to update favorite status",
        variant: "destructive",
      });
    }
  };

  const handleCreateFolder = (folderName: string) => {
    if (DEFAULT_FOLDERS.includes(folderName)) {
      toast({
        title: "Error",
        description: "This folder name is reserved.",
        variant: "destructive",
      });
      return;
    }

    if (folders.includes(folderName)) {
      toast({
        title: "Error",
        description: "A folder with this name already exists.",
        variant: "destructive",
      });
      return;
    }

    console.log('Creating new folder:', folderName);
    const updatedFolders = [...folders, folderName];
    setFolders(updatedFolders);
    setCurrentFolder(folderName);
    
    toast({
      title: "Success",
      description: "Folder created successfully!",
    });
  };

  const handleEditFolder = (oldName: string, newName: string) => {
    if (oldName === newName) return;

    if (DEFAULT_FOLDERS.includes(oldName)) {
      toast({
        title: "Error",
        description: "Cannot edit this folder.",
        variant: "destructive",
      });
      return;
    }

    if (folders.includes(newName)) {
      toast({
        title: "Error",
        description: "A folder with this name already exists.",
        variant: "destructive",
      });
      return;
    }

    const updatedFolders = folders.map(f => f === oldName ? newName : f);
    setFolders(updatedFolders);

    const updatedMemories = memories.map(memory => ({
      ...memory,
      folder: memory.folder === oldName ? newName : memory.folder
    }));
    setMemories(updatedMemories);
    localStorage.setItem("memories", JSON.stringify(updatedMemories));

    if (currentFolder === oldName) {
      setCurrentFolder(newName);
    }

    toast({
      title: "Success",
      description: "Folder renamed successfully!",
    });
  };

  const handleDeleteFolder = (folderName: string) => {
    if (DEFAULT_FOLDERS.includes(folderName)) {
      toast({
        title: "Error",
        description: "Cannot delete this folder.",
        variant: "destructive",
      });
      return;
    }

    const updatedMemories = memories.map(memory => ({
      ...memory,
      folder: memory.folder === folderName ? "" : memory.folder
    }));
    setMemories(updatedMemories);
    localStorage.setItem("memories", JSON.stringify(updatedMemories));

    setFolders(folders.filter(f => f !== folderName));

    if (currentFolder === folderName) {
      setCurrentFolder("");
    }

    toast({
      title: "Success",
      description: "Folder deleted successfully!",
    });
  };

  const handleProfilePhotoUpdate = (url: string) => {
    if (lovedOne) {
      setLovedOne({
        ...lovedOne,
        photo_url: url
      });
    }
  };

  const handleToggleFavorites = () => {
    setShowFavorites(!showFavorites);
  };

  const handleAddMemory = async () => {
    console.log('handleAddMemory called');
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) {
      console.log('No authenticated user found');
      return;
    }

    if (!lovedOne?.id) {
      console.log('No loved one selected');
      toast({
        title: "Error",
        description: "Please select a loved one first",
        variant: "destructive",
      });
      return;
    }

    const { count } = await supabase
      .from('memories')
      .select('*', { count: 'exact', head: true })
      .eq('loved_one_id', lovedOne.id);

    console.log('Current memory count:', count);
    const memoryCount = count || 0;

    if (memoryCount >= 5 && !isSubscribed) {
      console.log('Memory limit reached and user not subscribed, showing subscription dialog');
      setShowSubscriptionDialog(true);
      return;
    }

    console.log('Showing memory form');
    setShowForm(true);
    setEditingMemory(undefined);
    
    setTimeout(() => {
      const memoryForm = document.querySelector('.memory-card');
      if (memoryForm) {
        memoryForm.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  const handleSortChange = (value: SortOption) => {
    setSortOption(value);
  };

  useEffect(() => {
    const hasSeenFirstMemoryDialog = localStorage.getItem('hasSeenFirstMemoryDialog');
    console.log('Checking first memory dialog state:', {
      totalMemoryCount,
      hasSeenDialog: hasSeenFirstMemoryDialog
    });
    
    if (totalMemoryCount === 1 && !hasSeenFirstMemoryDialog) {
      console.log('Showing first memory dialog - user has never seen it before');
      setShowFirstMemoryDialog(true);
    }
  }, [totalMemoryCount]);

  const handleFirstMemoryDialogClose = () => {
    console.log('Handling first memory dialog close');
    localStorage.setItem('hasSeenFirstMemoryDialog', 'true');
    setShowFirstMemoryDialog(false);
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-cream-50 to-white">
      <Navigation />
      <div className="container mx-auto px-4 py-12 pb-24 lg:pb-12">
        <div className="max-w-7xl mx-auto">
          <VaultHeader 
            lovedOne={lovedOne} 
            onPhotoUpdate={handleProfilePhotoUpdate}
          />
          
          <VaultActions
            onNewMemory={handleAddMemory}
            onCreateFolder={() => {
              setShowFolderDialog(true);
              setEditingFolder(null);
            }}
            searchQuery={searchQuery}
            onSearchChange={setSearchQuery}
            folders={folders}
            currentFolder={currentFolder}
            onFolderChange={setCurrentFolder}
            showFavorites={showFavorites}
            onToggleFavorites={handleToggleFavorites}
            sortOption={sortOption}
            onSortChange={handleSortChange}
          />
          
          <VaultContent
            memories={filteredMemories}
            showForm={showForm}
            editingMemory={editingMemory}
            currentFolder={currentFolder}
            onEdit={handleEditMemory}
            onDelete={handleDeleteMemory}
            onDragEnd={handleDragEnd}
            onSave={handleSaveMemory}
            onCancel={() => {
              setShowForm(false);
              setEditingMemory(undefined);
            }}
            sortOption={sortOption}
            onSortChange={handleSortChange}
            onToggleFavorite={handleToggleFavorite}
            folders={folders}
            lovedOneName={lovedOne?.name}
            totalMemoryCount={totalMemoryCount}
          />
        </div>
      </div>

      <MobileNavigation
        onNewMemory={handleAddMemory}
        onCreateFolder={() => {
          console.log('Opening folder dialog');
          setShowFolderDialog(true);
          setEditingFolder(null);
        }}
        searchQuery={searchQuery}
        onSearchChange={setSearchQuery}
        folders={folders}
        currentFolder={currentFolder}
        onFolderChange={(folder) => {
          console.log('Changing folder to:', folder);
          setCurrentFolder(folder);
        }}
        sortOption={sortOption}
        onSortChange={handleSortChange}
      />

      <FolderDialog
        isOpen={showFolderDialog}
        onClose={() => {
          console.log('Closing folder dialog');
          setShowFolderDialog(false);
          setEditingFolder(null);
        }}
        onSave={(folderName) => {
          console.log('Saving folder:', folderName);
          if (editingFolder) {
            handleEditFolder(editingFolder, folderName);
          } else {
            handleCreateFolder(folderName);
          }
          setEditingFolder(null);
        }}
        initialValue={editingFolder || ''}
        title={editingFolder ? 'Edit Folder' : 'Create New Folder'}
      />

      <SubscriptionDialog
        isOpen={showSubscriptionDialog}
        onClose={() => setShowSubscriptionDialog(false)}
        trigger="memories"
      />

      <Dialog 
        open={showFirstMemoryDialog} 
        onOpenChange={(open) => {
          if (!open) {
            handleFirstMemoryDialogClose();
          }
        }}
      >
        <DialogContent>
          <DialogHeader>
            <DialogTitle>You've added your first memory!</DialogTitle>
            <DialogDescription>
              Would you like to add more memories, or would you like to share text samples from {lovedOne?.name} so that you can feel connected through chat?
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex flex-col sm:flex-row gap-2">
            <Button
              variant="outline"
              onClick={handleFirstMemoryDialogClose}
              className="w-full sm:w-auto"
            >
              Add Memories
            </Button>
            <Button
              onClick={() => {
                handleFirstMemoryDialogClose();
                navigate("/training");
              }}
              className="w-full sm:w-auto"
            >
              Start Chat
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Vault;
