import { Star } from "lucide-react";

const testimonials = [
  {
    text: "This platform has helped me maintain a connection with my father's memory in a way I never thought possible.",
    author: "Sarah M."
  },
  {
    text: "The Memory Vault feature lets me save all the little moments I never want to forget. It's become my digital sanctuary.",
    author: "Michael R."
  }
];

export const TestimonialsSection = () => {
  return (
    <section className="bg-gradient-to-r from-cream-50 to-lavender-50 py-20">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-center text-lavender-800 mb-12">
          Stories from Our Community
        </h2>
        <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <div 
              key={index}
              className="bg-white p-6 rounded-xl shadow-sm border border-lavender-100"
            >
              <div className="flex gap-2 mb-4">
                {[...Array(5)].map((_, i) => (
                  <Star key={i} className="h-5 w-5 text-facebook-400 fill-facebook-400" />
                ))}
              </div>
              <p className="text-lavender-700 mb-4 italic">"{testimonial.text}"</p>
              <p className="text-lavender-600 font-semibold">{testimonial.author}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};