import React from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

interface FolderDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (folderName: string) => void;
  initialValue?: string;
  title: string;
}

const FolderDialog = ({ isOpen, onClose, onSave, initialValue = '', title }: FolderDialogProps) => {
  const [folderName, setFolderName] = React.useState(initialValue);

  const handleSave = () => {
    if (folderName.trim()) {
      onSave(folderName.trim());
      onClose();
    }
  };

  React.useEffect(() => {
    setFolderName(initialValue);
  }, [initialValue]);

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div className="py-4">
          <Input
            placeholder="Folder name"
            value={folderName}
            onChange={(e) => setFolderName(e.target.value)}
            className="w-full"
          />
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FolderDialog;