import { Button } from "@/components/ui/button";
import { ArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const HeroSection = () => {
  const navigate = useNavigate();

  return (
    <section className="container mx-auto px-4 py-20 text-center relative overflow-hidden bg-gradient-to-b from-[#F1F0FB] to-[#D3E4FD]">
      <div className="absolute inset-0 opacity-5" />
      <div className="relative z-10 max-w-4xl mx-auto animate-fadeIn">
        <h1 className="text-6xl font-bold mb-6 text-black">
          Honor Their Memory, <br />Feel Connected
        </h1>
        <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
          A private digital space for sharing and cherishing memories of those who are no longer with you
        </p>
        <div className="flex flex-col gap-4 items-center justify-center">
          <Button
            onClick={() => navigate("/onboarding")}
            size="lg"
            className="text-lg group transition-all duration-300 hover:translate-y-[-2px] hover:shadow-lg bg-facebook-500 hover:bg-facebook-600 w-full max-w-xs"
          >
            Begin Your Journey
            <ArrowRight className="ml-2 group-hover:translate-x-1 transition-transform" />
          </Button>
          <Button
            variant="outline"
            size="lg"
            className="text-lg border-2 hover:bg-lavender-50 w-full max-w-xs"
            onClick={() => navigate("/login")}
          >
            Sign In
          </Button>
        </div>
      </div>
    </section>
  );
};