import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";
import { Navigation } from "@/components/Navigation";
import { useEffect, useState } from "react";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { Footer } from "@/components/Footer";
import { useSubscription } from "@/hooks/useSubscription";

const LovedOnesOptions = () => {
  const navigate = useNavigate();
  const [hasLovedOnes, setHasLovedOnes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { toast } = useToast();
  const { isSubscribed } = useSubscription();

  useEffect(() => {
    const checkForLovedOnes = async () => {
      try {
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        
        if (authError || !user) {
          console.error('Authentication error:', authError);
          toast({
            title: "Authentication Error",
            description: "Please sign in to continue",
            variant: "destructive",
          });
          navigate('/login');
          return;
        }

        console.log('Checking for loved ones...');
        const { data: lovedOnes, error } = await supabase
          .from('loved_ones')
          .select('id, name, relationship')
          .eq('user_id', user.id);

        if (error) {
          console.error('Error checking for loved ones:', error);
          toast({
            title: "Error",
            description: "Failed to check for loved ones",
            variant: "destructive",
          });
          return;
        }

        console.log('Found loved ones:', lovedOnes);
        
        if (lovedOnes && lovedOnes.length > 0) {
          setHasLovedOnes(true);
          const firstLovedOne = lovedOnes[0];
          localStorage.setItem("lovedOne", JSON.stringify({
            id: firstLovedOne.id,
            name: firstLovedOne.name,
            relationship: firstLovedOne.relationship
          }));
          localStorage.setItem("lovedOneId", firstLovedOne.id.toString());
          localStorage.setItem("conversationId", firstLovedOne.id.toString());
        }
      } catch (error) {
        console.error('Unexpected error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkForLovedOnes();
  }, [navigate, toast]);

  const handleManageSubscription = async () => {
    try {
      if (isSubscribed) {
        // For active subscribers, create a portal session
        const { data, error } = await supabase.functions.invoke('create-portal-session', {});
        
        if (error) throw error;
        
        if (data?.url) {
          window.location.href = data.url;
        }
      } else {
        // For non-subscribers, show subscription plans
        navigate("/subscription");
      }
    } catch (error) {
      console.error('Error managing subscription:', error);
      toast({
        title: "Error",
        description: "Failed to access subscription management. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col bg-cream-50">
        <Navigation />
        <div className="flex-1 flex items-center justify-center">
          <div className="text-sage-600">Loading...</div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-cream-50">
      <Navigation />
      <div className="flex-1 flex flex-col items-center justify-center p-4">
        <div className="w-full max-w-md space-y-6">
          <div className="space-y-4">
            <div className="text-center space-y-2">
              <h2 className="text-2xl font-semibold text-sage-800">Welcome</h2>
              <p className="text-sage-600">What would you like to do?</p>
            </div>

            <div className="space-y-4">
              {hasLovedOnes && (
                <Button
                  onClick={() => navigate("/dashboard")}
                  className="w-full bg-facebook-600 hover:bg-facebook-700 text-white"
                >
                  View Your Loved Ones
                </Button>
              )}

              <Button
                onClick={() => navigate("/add-loved-one")}
                className="w-full bg-facebook-600 hover:bg-facebook-700 text-white"
              >
                Add a New Loved One
              </Button>
            </div>
          </div>
          
          <div className="flex justify-center">
            <Button
              onClick={handleManageSubscription}
              size="sm"
              className="bg-black hover:bg-gray-800 text-white text-sm"
            >
              {isSubscribed ? "Manage Subscription" : "View Subscription Plans"}
            </Button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LovedOnesOptions;