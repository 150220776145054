import { Card } from "@/components/ui/card";
import ProfilePhotoUpload from "../ProfilePhotoUpload";

interface VaultHeaderProps {
  lovedOne: {
    id: number;
    name: string;
    relationship: string;
    photo_url?: string | null;
  } | null;
  onPhotoUpdate: (url: string) => void;
}

const VaultHeader = ({ lovedOne, onPhotoUpdate }: VaultHeaderProps) => {
  if (!lovedOne) return null;

  return (
    <Card className="p-6 mb-8 bg-white shadow-sm animate-fadeIn">
      <div className="flex flex-col md:flex-row items-start md:items-center gap-6">
        <ProfilePhotoUpload
          lovedOneId={lovedOne.id}
          photoUrl={lovedOne.photo_url || null}
          onPhotoUpdate={onPhotoUpdate}
          className="w-24 h-24 rounded-full"
        />
        <div className="flex-1">
          <div className="flex flex-col md:flex-row md:items-center justify-between gap-4">
            <div>
              <h1 className="text-2xl font-semibold text-gray-900">
                {lovedOne.name}'s Memory Vault
              </h1>
              <p className="text-gray-600 mt-1">{lovedOne.relationship}</p>
            </div>
          </div>
          <p className="text-gray-600 mt-4">
            Cherish and revisit the special moments that keep their memory alive.
          </p>
        </div>
      </div>
    </Card>
  );
};

export default VaultHeader;