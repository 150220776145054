import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { useToast } from "@/components/ui/use-toast";
import PhotoUpload from "./PhotoUpload";
import { saveMemory } from "@/services/memoryService";
import { supabase } from "@/integrations/supabase/client";
import { uploadPhoto } from "./PhotoUploadHandler";
import { useMemoryValidation } from "./useMemoryValidation";
import { Memory, PartialMemory } from "@/types/memory";

interface MemoryFormProps {
  onSave: (memory: Memory) => void;
  onCancel: () => void;
  editingMemory?: Memory;
  folders?: string[];
}

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const currentYear = new Date().getFullYear();
const years = Array.from({ length: 121 }, (_, i) => currentYear - i);

const MemoryForm = ({ onSave, onCancel, editingMemory, folders = [] }: MemoryFormProps) => {
  console.log('MemoryForm rendered with folders:', folders);
  
  const [memory, setMemory] = useState<PartialMemory>({
    title: "",
    description: "",
    photoUrl: "",
    photoCaption: "",
    memory_month: undefined,
    memory_year: undefined,
    folder: "",
  });
  const [photoFile, setPhotoFile] = useState<File | null>(null);
  const [photoPreview, setPhotoPreview] = useState<string>("");
  const [isSaving, setIsSaving] = useState(false);
  const { toast } = useToast();
  const { validateMemory, validateLovedOne } = useMemoryValidation();

  useEffect(() => {
    if (editingMemory) {
      setMemory(editingMemory);
      if (editingMemory.photoUrl) {
        setPhotoPreview(editingMemory.photoUrl);
      }
    }
  }, [editingMemory]);

  const handlePhotoChange = (file: File) => {
    setPhotoFile(file);
    const previewUrl = URL.createObjectURL(file);
    setPhotoPreview(previewUrl);
    setMemory({ ...memory, photoUrl: previewUrl });
  };

  const removePhoto = () => {
    setPhotoFile(null);
    setPhotoPreview("");
    setMemory({ ...memory, photoUrl: "", photoCaption: "" });
  };

  const handleSubmit = async () => {
    if (isSaving) return;
    
    try {
      setIsSaving(true);
      console.log('Starting memory save process...', memory);
      
      const isValid = await validateMemory(memory as Memory);
      if (!isValid) {
        setIsSaving(false);
        return;
      }

      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError || !user) {
        toast({
          title: "Authentication required",
          description: "Please sign in to save memories",
          variant: "destructive",
        });
        setIsSaving(false);
        return;
      }

      const lovedOneStr = localStorage.getItem('lovedOne');
      if (!lovedOneStr) {
        toast({
          title: "Error",
          description: "No loved one selected. Please try again.",
          variant: "destructive",
        });
        setIsSaving(false);
        return;
      }

      const lovedOne = JSON.parse(lovedOneStr);
      const verifiedLovedOne = await validateLovedOne(user.id, lovedOne.id);
      if (!verifiedLovedOne) {
        setIsSaving(false);
        return;
      }

      let photoUrl = memory.photoUrl;
      if (photoFile) {
        photoUrl = await uploadPhoto(photoFile, toast);
        if (!photoUrl) {
          setIsSaving(false);
          return;
        }
      }

      console.log('Saving memory with folder:', memory.folder);

      const savedMemory = await saveMemory({
        title: memory.title || "",
        description: memory.description || "",
        lovedOneId: verifiedLovedOne.id,
        memory_month: memory.memory_month,
        memory_year: memory.memory_year,
        photoCaption: memory.photoCaption,
        folder: memory.folder || "",
      });

      console.log('Memory saved successfully:', savedMemory);
      
      const newMemory: Memory = {
        id: editingMemory?.id || Date.now().toString(),
        title: memory.title || "",
        description: memory.description || "",
        date: editingMemory?.date || new Date().toISOString(),
        photoUrl: photoUrl || "",
        photoCaption: memory.photoCaption,
        memory_month: memory.memory_month,
        memory_year: memory.memory_year,
        folder: memory.folder || "",
      };

      onSave(newMemory);
      
    } catch (error) {
      console.error("Error saving memory:", error);
      toast({
        title: "Error",
        description: error instanceof Error ? error.message : "Failed to save memory",
        variant: "destructive",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="memory-card mb-8 animate-slideUp bg-white p-6 rounded-lg shadow-md">
      <h3 className="text-xl font-semibold text-sage-800 mb-4">
        {editingMemory ? "Edit Memory" : "Add New Memory"}
      </h3>
      <div className="space-y-4">
        <Input
          placeholder="Memory Title"
          value={memory.title}
          onChange={(e) => setMemory({ ...memory, title: e.target.value })}
          className="input-field"
        />
        
        <PhotoUpload
          photoPreview={photoPreview}
          onPhotoChange={handlePhotoChange}
          onRemovePhoto={removePhoto}
        />

        {photoPreview && (
          <Input
            placeholder="Add a caption to your photo (optional)"
            value={memory.photoCaption}
            onChange={(e) => setMemory({ ...memory, photoCaption: e.target.value })}
            className="input-field"
          />
        )}

        <Textarea
          placeholder="Describe your memory..."
          value={memory.description}
          onChange={(e) => setMemory({ ...memory, description: e.target.value })}
          className="input-field min-h-[150px]"
        />

        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Month (optional)
            </label>
            <Select
              value={memory.memory_month?.toString()}
              onValueChange={(value) => 
                setMemory({ ...memory, memory_month: parseInt(value) })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select month" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="null">No month selected</SelectItem>
                {months.map((month, index) => (
                  <SelectItem key={month} value={(index + 1).toString()}>
                    {month}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Year (optional)
            </label>
            <Select
              value={memory.memory_year?.toString()}
              onValueChange={(value) => 
                setMemory({ ...memory, memory_year: parseInt(value) })
              }
            >
              <SelectTrigger>
                <SelectValue placeholder="Select year" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="null">No year selected</SelectItem>
                {years.map((year) => (
                  <SelectItem key={year} value={year.toString()}>
                    {year}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>

          <div className="flex-1">
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Folder
            </label>
            <Select
              value={memory.folder || "_none"}
              onValueChange={(value) => setMemory({ ...memory, folder: value === "_none" ? "" : value })}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select folder" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="_none">All Memories</SelectItem>
                {folders.filter(folder => folder !== "Favorites").map((folder) => (
                  <SelectItem key={folder} value={folder}>
                    {folder}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>
        
        <div className="flex gap-4">
          <Button 
            onClick={handleSubmit} 
            className="bg-facebook-500 hover:bg-facebook-600 text-white flex-1"
          >
            {editingMemory ? "Save Changes" : "Save Memory"}
          </Button>
          <Button
            onClick={onCancel}
            variant="outline"
            className="flex-1"
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MemoryForm;
