import { Navigation } from "@/components/Navigation";
import { Footer } from "@/components/Footer";

const Privacy = () => {
  return (
    <div className="min-h-screen flex flex-col bg-cream-50">
      <Navigation />
      <main className="flex-1 container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
        <div className="prose max-w-none">
          <p className="text-sm text-gray-600 mb-6">Effective Date: December 29, 2024</p>

          <p className="mb-6">MemorySpace.co ("we," "our," or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your information and outlines your rights regarding the data you provide to us. By using our website and services, you agree to the terms of this Privacy Policy.</p>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">1. Data Collection</h2>
            <p>We collect the following types of information:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Personal Information: Name, email address, uploaded photos, message samples, and loved one names.</li>
              <li>Usage Data: Information collected automatically through Google Analytics, such as IP address, device type, and browsing behavior.</li>
              <li>AI Interaction Inputs: Information provided by users to facilitate AI-generated messaging, including specific text samples and related data.</li>
            </ul>
            <p>We also use cookies and tracking technologies via Google Analytics to enhance the user experience and analyze platform performance.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">2. Data Use</h2>
            <p>We use your data for the following purposes:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Account Creation: To set up and maintain your account.</li>
              <li>Providing Services: To enable memory storage, AI-generated messaging, and sharing of uploaded content.</li>
              <li>Marketing and Communications: To send updates and promotional emails.</li>
            </ul>
            <p>AI-generated inputs are processed solely for the purpose of enabling personalized interactions; they are not used to train or improve AI models outside of your experience.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">3. Data Sharing</h2>
            <p>We share your data only as necessary and with the following third-party providers:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Google Analytics: For usage analytics and platform performance tracking.</li>
              <li>AI Services & Tools: For processing AI-generated messages.</li>
              <li>Email Services: For account notifications and marketing communications.</li>
            </ul>
            <p>We do not sell or share your data for advertising purposes beyond the services explicitly listed above.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">4. User Rights</h2>
            <p>Data Deletion: You may request the deletion of your account and all associated data by contacting us at support@memoryspace.co.</p>
            <p>Privacy Settings: All uploaded memories are private by default. Users must explicitly invite others to share access to specific content.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">5. Data Retention</h2>
            <p>We retain user data only for as long as necessary to provide our services. Data will be deleted upon account closure.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">6. Data Security</h2>
            <p>We store your data in secure databases and implement reasonable measures to protect it from unauthorized access. However, no method of transmission or storage is completely secure, and we cannot guarantee absolute security.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">7. Cookies and Tracking</h2>
            <p>We use Google Analytics cookies and similar tracking technologies to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Monitor platform performance.</li>
              <li>Understand user behavior.</li>
            </ul>
            <p>You can control or disable cookies through your browser settings.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">8. Children's Data</h2>
            <p>Our platform is not intended for users under the age of 18. We do not knowingly collect personal information from children.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">9. International Users</h2>
            <p>MemorySpace.co is available internationally. We comply with applicable laws, including the General Data Protection Regulation (GDPR) for EU users and the California Consumer Privacy Act (CCPA) for California residents. If you are a resident of these jurisdictions, you have specific rights, including the right to access, correct, or delete your data.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">10. Contact Information</h2>
            <p>If you have questions or concerns about this Privacy Policy or how your data is handled, please contact us at:</p>
            <p>Email: support@memoryspace.co</p>
          </section>

          <p className="mt-8">By using MemorySpace.co, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default Privacy;