import { Message, SupabaseMessage } from "@/types/messages";

export const formatSupabaseMessage = (msg: SupabaseMessage): Message => ({
  id: msg.id.toString(),
  text: msg.content,
  sender: msg.is_user ? "user" : "loved-one",
  timestamp: new Date(msg.created_at),
});

export const getRandomDelay = (messageLength: number) => {
  const baseDelay = Math.random() * 500 + 300;
  const typingDelay = messageLength * (Math.random() * 20 + 20);
  return Math.min(baseDelay + typingDelay, 3000);
};

export const isDuplicateMessage = (newMsg: Message, existingMsgs: Message[]): boolean => {
  return existingMsgs.some(msg => {
    // First check by ID if available
    if (newMsg.id && msg.id && msg.id === newMsg.id) {
      console.log('Duplicate detected by ID match:', newMsg.id);
      return true;
    }

    // Then check content and sender
    const contentMatch = msg.text.trim() === newMsg.text.trim();
    const senderMatch = msg.sender === newMsg.sender;
    
    // More permissive time window (5 seconds instead of 1)
    const timeMatch = Math.abs(msg.timestamp.getTime() - newMsg.timestamp.getTime()) < 5000;
    
    const isDuplicate = contentMatch && senderMatch && timeMatch;

    if (isDuplicate) {
      console.log('Duplicate message detected:', {
        existing: {
          id: msg.id,
          text: msg.text,
          sender: msg.sender,
          timestamp: msg.timestamp.toISOString()
        },
        new: {
          id: newMsg.id,
          text: newMsg.text,
          sender: newMsg.sender,
          timestamp: newMsg.timestamp.toISOString()
        },
        matchCriteria: {
          contentMatch,
          senderMatch,
          timeMatch
        }
      });
    }
    return isDuplicate;
  });
};