import React from 'react';
import { ScrollArea } from "@/components/ui/scroll-area";

interface SavedSamplesListProps {
  samples: string[];
}

const SavedSamplesList = ({ samples }: SavedSamplesListProps) => {
  // Ensure samples is an array and has items
  const validSamples = Array.isArray(samples) ? samples : [];
  
  console.log('SavedSamplesList received samples:', samples);
  console.log('Validated samples:', validSamples);
  
  if (validSamples.length === 0) {
    console.log('No valid samples to display');
    return null;
  }

  return (
    <ScrollArea className="h-[200px] w-full rounded-md border p-4 mb-4">
      <div className="space-y-2">
        {validSamples.map((sample, index) => (
          <div key={index} className="p-2 bg-cream-50 rounded-md">
            <p className="text-sm text-gray-600">{sample}</p>
          </div>
        ))}
      </div>
    </ScrollArea>
  );
};

export default SavedSamplesList;