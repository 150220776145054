import { supabase } from "@/integrations/supabase/client";
import { Memory } from "@/types/memory";

export const saveMemory = async ({
  title,
  description,
  lovedOneId,
  memory_month,
  memory_year,
  photoCaption,
  folder,
}: {
  title: string;
  description: string;
  lovedOneId: number;
  memory_month?: number;
  memory_year?: number;
  photoCaption?: string;
  folder?: string;
}) => {
  console.log('Saving memory with data:', {
    title,
    description,
    lovedOneId,
    memory_month,
    memory_year,
    photoCaption,
    folder,
  });

  const { data, error } = await supabase
    .from('memories')
    .insert({
      context: title,
      content: description,
      loved_one_id: lovedOneId,
      memory_month,
      memory_year,
      photo_caption: photoCaption,
      folder: folder || '',
    })
    .select()
    .single();

  if (error) {
    console.error('Error saving memory:', error);
    throw error;
  }

  console.log('Memory saved successfully:', data);
  return data;
};