import React from "react";

interface SavedExamplesCounterProps {
  count: number;
}

const SavedExamplesCounter = ({ count }: SavedExamplesCounterProps) => {
  if (count === 0) return null;

  return (
    <p className="text-sm text-sage-600">
      {count} example{count > 1 ? 's' : ''} saved
    </p>
  );
};

export default SavedExamplesCounter;