import { useState, useEffect } from 'react';
import { supabase } from '@/integrations/supabase/client';
import { useToast } from '@/hooks/use-toast';

export type SubscriptionTier = 'weekly' | 'monthly' | 'annual' | null;

interface SubscriptionStatus {
  isSubscribed: boolean;
  tier: SubscriptionTier;
  expiresAt: string | null;
}

export const useSubscription = (specificEmail?: string) => {
  const [status, setStatus] = useState<SubscriptionStatus>({
    isSubscribed: false,
    tier: null,
    expiresAt: null
  });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);
  const [checkInProgress, setCheckInProgress] = useState(false);
  const { toast } = useToast();

  const checkSubscription = async () => {
    // Prevent multiple simultaneous checks
    if (checkInProgress) {
      console.log('🔄 Subscription check already in progress, skipping...');
      return;
    }

    console.log('🔄 Starting subscription check...', { specificEmail });
    setCheckInProgress(true);

    try {
      const { data: { session } } = await supabase.auth.getSession();
      console.log('Session check result:', !!session);
      
      if (!session && !specificEmail) {
        console.log('No active session found and no specific email provided, setting unsubscribed status');
        setStatus({ isSubscribed: false, tier: null, expiresAt: null });
        setIsLoading(false);
        return;
      }

      // Check for test mode
      const testSubscription = localStorage.getItem('test_subscription');
      console.log('Test subscription mode:', testSubscription);
      
      if (testSubscription === 'true') {
        console.log('Using test subscription mode');
        const testStatus = {
          isSubscribed: true,
          tier: 'annual' as SubscriptionTier,
          expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toISOString()
        };
        console.log('Setting test subscription status:', testStatus);
        setStatus(testStatus);
        setIsLoading(false);
        return;
      }

      // Use query parameters for email instead of path
      const queryParams = specificEmail ? `?email=${encodeURIComponent(specificEmail)}` : '';
      console.log('Making check-subscription function call...', { queryParams });
      
      const response = await supabase.functions.invoke('check-subscription', {
        body: { email: specificEmail }
      });
      
      console.log('Subscription check response:', response);
      
      if (response.error) {
        console.error('Error checking subscription:', response.error);
        setError(new Error(response.error.message));
        throw new Error(response.error.message);
      }

      const { isSubscribed, tier, expiresAt } = response.data;
      console.log('Setting subscription status:', { isSubscribed, tier, expiresAt });
      
      // Only update state if the component is still mounted and the status has changed
      setStatus(prevStatus => {
        const newStatus = { isSubscribed, tier, expiresAt };
        const hasChanged = JSON.stringify(prevStatus) !== JSON.stringify(newStatus);
        if (hasChanged) {
          console.log('Subscription status changed:', { 
            from: prevStatus, 
            to: newStatus 
          });
        }
        return hasChanged ? newStatus : prevStatus;
      });

    } catch (error: any) {
      console.error('Error in useSubscription:', error);
      setError(error);
      toast({
        title: "Error",
        description: "Failed to check subscription status",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
      setCheckInProgress(false);
    }
  };

  // Check subscription on mount and every hour
  useEffect(() => {
    console.log('🔄 useSubscription hook mounted, checking subscription...', { specificEmail });
    checkSubscription();
    const interval = setInterval(checkSubscription, 60 * 60 * 1000); // Check every hour
    return () => {
      clearInterval(interval);
      console.log('🧹 Cleaning up subscription check interval');
    };
  }, [specificEmail]); // Re-run when specificEmail changes

  // Log current subscription state
  useEffect(() => {
    console.log('Subscription state updated:', {
      isSubscribed: status.isSubscribed,
      tier: status.tier,
      expiresAt: status.expiresAt,
      isLoading,
      error: error?.message,
      checkInProgress
    });
  }, [status, isLoading, error, checkInProgress]);

  const toggleTestSubscription = () => {
    const currentTest = localStorage.getItem('test_subscription');
    if (currentTest === 'true') {
      localStorage.removeItem('test_subscription');
      toast({
        title: "Test Mode Disabled",
        description: "Subscription test mode has been disabled",
      });
    } else {
      localStorage.setItem('test_subscription', 'true');
      toast({
        title: "Test Mode Enabled",
        description: "Subscription test mode has been enabled",
      });
    }
    checkSubscription();
  };

  return {
    ...status,
    isLoading,
    error,
    checkSubscription,
    toggleTestSubscription
  };
};