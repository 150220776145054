import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { Navigation } from "@/components/Navigation";
import { Footer } from "@/components/Footer";
import { Alert, AlertDescription } from "@/components/ui/alert";

const SharedMemories = () => {
  const navigate = useNavigate();
  const [hasSharedMemories, setHasSharedMemories] = useState<boolean | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkSharedMemories = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        
        if (!user) {
          navigate('/login');
          return;
        }

        console.log('Checking for shared memories...');
        const { data: shares, error } = await supabase
          .from('memory_shares')
          .select('*')
          .eq('shared_with_email', user.email);

        if (error) {
          console.error('Error checking shared memories:', error);
          throw error;
        }

        console.log('Shared memories result:', shares);
        setHasSharedMemories(shares && shares.length > 0);
      } catch (error) {
        console.error('Error in checkSharedMemories:', error);
      } finally {
        setIsLoading(false);
      }
    };

    checkSharedMemories();
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex flex-col bg-cream-50">
        <Navigation />
        <div className="flex-1 flex items-center justify-center">
          <div className="text-sage-600">Loading...</div>
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-cream-50">
      <Navigation />
      <div className="flex-1 flex items-center justify-center p-4">
        {!hasSharedMemories ? (
          <div className="w-full max-w-md">
            <Alert>
              <AlertDescription>
                You haven't been invited to share memories for others yet.
              </AlertDescription>
            </Alert>
          </div>
        ) : (
          <div className="w-full max-w-4xl">
            <div className="bg-white rounded-lg shadow p-6">
              <h2 className="text-2xl font-semibold text-sage-800 mb-4">Shared Memories</h2>
              <p className="text-sage-600">
                Here you can view and contribute to memories for loved ones you've been invited to share with.
              </p>
              {/* Add your shared memories list/grid here */}
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default SharedMemories;