import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { X } from "lucide-react";
import { trainAI } from "@/lib/api-client";
import TextSampleForm from "./training/TextSampleForm";
import SavedExamplesCounter from "./training/SavedExamplesCounter";
import { supabase } from "@/integrations/supabase/client";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import { useNavigate } from "react-router-dom";

interface UploadModalProps {
  open: boolean;
  onClose: () => void;
}

const UploadModal = ({ open, onClose }: UploadModalProps) => {
  const [textSamples, setTextSamples] = useState<string[]>(['']);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session }, error } = await supabase.auth.getSession();
      if (error) {
        console.error('Auth check error:', error);
        setIsAuthenticated(false);
        return;
      }
      setIsAuthenticated(!!session);
      console.log('Auth state:', session ? 'Authenticated' : 'Not authenticated');
    };

    checkAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Auth state changed:', event, session);
      setIsAuthenticated(!!session);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Reset states when modal is opened/closed
  useEffect(() => {
    console.log('Modal state changed:', open ? 'opened' : 'closed');
    if (!open) {
      console.log('Resetting modal states');
      setTextSamples(['']);
      setShowSuccessDialog(false);
    }
  }, [open]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    console.log('Starting handleSubmit...');
    
    const validSamples = textSamples.filter(sample => sample.trim().length > 0);
    console.log('Valid samples count:', validSamples.length);
    
    if (validSamples.length === 0) {
      console.log('No valid samples found');
      toast({
        title: "Please enter at least one message example",
        description: "The text example field cannot be empty",
      });
      return;
    }

    try {
      if (!isAuthenticated) {
        console.log('User not authenticated');
        toast({
          title: "Authentication required",
          description: "Please sign in to save training examples",
          variant: "destructive",
        });
        return;
      }

      const lovedOne = JSON.parse(localStorage.getItem("lovedOne") || "{}");
      const lovedOneId = lovedOne?.id;
      console.log('Retrieved loved one ID:', lovedOneId);

      if (!lovedOneId) {
        console.error('No loved one ID found in localStorage');
        toast({
          title: "Error",
          description: "Could not find loved one information. Please try setting up your loved one again.",
          variant: "destructive",
        });
        return;
      }

      console.log('Submitting training examples:', {
        samples: validSamples,
        lovedOneId
      });

      await trainAI(validSamples, lovedOneId);

      // Get existing examples before updating
      const stored = localStorage.getItem("trainingExamples") || "{}";
      const existingExamples = JSON.parse(stored);
      
      // Check if this is the first training sample
      const isFirstSample = !existingExamples[lovedOneId] || existingExamples[lovedOneId].length === 0;
      
      console.log('Is first sample:', isFirstSample);
      console.log('Existing examples:', existingExamples);
      console.log('Loved one ID:', lovedOneId);

      if (isFirstSample) {
        console.log('This is the first sample - showing success dialog');
        setShowSuccessDialog(true);
      } else {
        console.log('Not first sample - showing toast');
        toast({
          title: "Examples saved",
          description: `Successfully saved ${validSamples.length} message example${validSamples.length > 1 ? 's' : ''}.`,
        });
        onClose();
      }

      // Update localStorage after checking for first sample
      const newExamples = {
        ...existingExamples,
        [lovedOneId]: [
          ...(existingExamples[lovedOneId] || []),
          ...validSamples
        ]
      };
      localStorage.setItem("trainingExamples", JSON.stringify(newExamples));
      console.log('Updated localStorage with new examples');
      
      setTextSamples(['']);
    } catch (error: any) {
      console.error('Error saving examples:', error);
      toast({
        title: "Error",
        description: error?.message || "Failed to save the message examples. Please try again.",
        variant: "destructive",
      });
    }
  };

  if (!open) return null;

  return (
    <>
      <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 animate-fadeIn">
        <div className="bg-white rounded-lg p-6 w-full max-w-md mx-4">
          <div className="flex justify-between items-center mb-4">
            <div>
              <h3 className="text-lg font-semibold text-sage-800">
                Add Message Examples
              </h3>
              <SavedExamplesCounter count={0} />
            </div>
            <Button variant="ghost" size="icon" onClick={onClose}>
              <X className="h-5 w-5" />
            </Button>
          </div>

          <TextSampleForm
            textSamples={textSamples}
            onSampleChange={(index, value) => {
              const newSamples = [...textSamples];
              newSamples[index] = value;
              setTextSamples(newSamples);
            }}
            onAddSample={() => setTextSamples([...textSamples, ''])}
            onRemoveSample={(index) => {
              if (textSamples.length > 1) {
                const newSamples = textSamples.filter((_, i) => i !== index);
                setTextSamples(newSamples);
              }
            }}
            onSubmit={handleSubmit}
            onCancel={onClose}
          />
        </div>
      </div>

      <Dialog open={showSuccessDialog} onOpenChange={(open) => {
        console.log('Success dialog state changed:', open ? 'opened' : 'closed');
        if (!open) {
          setShowSuccessDialog(false);
          onClose();
        }
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Great start! 🎉</DialogTitle>
            <DialogDescription>
              You've added your first training example. Add more samples for better accuracy, but you can start messaging now!
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <Button
              onClick={() => {
                console.log('Navigating to messages from success dialog');
                setShowSuccessDialog(false);
                onClose();
                navigate("/messages");
              }}
            >
              Start Chatting
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default UploadModal;