import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import type { Database } from "@/integrations/supabase/types";

type LovedOneInsert = Database['public']['Tables']['loved_ones']['Insert'];

export const AddLovedOneForm = () => {
  const [name, setName] = useState("");
  const [relationship, setRelationship] = useState("");
  const navigate = useNavigate();
  const { toast } = useToast();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!name || !relationship) {
      toast({
        title: "Please fill in all fields",
        description: "Both name and relationship are required",
        variant: "destructive",
      });
      return;
    }

    try {
      const { data: { session }, error: sessionError } = await supabase.auth.getSession();
      
      if (sessionError || !session?.user?.id) {
        toast({
          title: "Authentication required",
          description: "Please sign in to continue",
          variant: "destructive",
        });
        return;
      }

      const lovedOneData: LovedOneInsert = {
        name,
        relationship,
        user_id: session.user.id,
        created_at: new Date().toISOString()
      };

      const { data, error } = await supabase
        .from('loved_ones')
        .insert([lovedOneData])
        .select('*')
        .single();

      if (error) throw error;

      if (!data) {
        throw new Error('No data returned from insert');
      }

      localStorage.setItem("lovedOne", JSON.stringify({
        name: data.name,
        relationship: data.relationship,
        id: data.id
      }));
      localStorage.setItem("lovedOneId", data.id.toString());
      localStorage.setItem("conversationId", data.id.toString());

      toast({
        title: "Success",
        description: "Your loved one has been added successfully",
      });

      navigate("/dashboard");
    } catch (error: any) {
      console.error('Failed to save loved one:', error);
      toast({
        title: "Error",
        description: error?.message || "Failed to save your loved one. Please try again.",
        variant: "destructive",
      });
    }
  };

  return (
    <div className="w-full max-w-md p-8 bg-white rounded-xl shadow-sm">
      <div className="mb-8">
        <h2 className="text-2xl font-semibold text-sage-800 mb-2">Add a New Loved One</h2>
        <p className="text-sage-600">Please enter their details below</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-2">
          <Input
            type="text"
            placeholder="Enter their name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="input-field"
          />
        </div>
        
        <div className="space-y-2">
          <Input
            type="text"
            placeholder="e.g., Parent, Sibling, Friend"
            value={relationship}
            onChange={(e) => setRelationship(e.target.value)}
            className="input-field"
          />
        </div>

        <div className="flex justify-between">
          <Button 
            type="button" 
            onClick={() => navigate("/loved-ones-options")} 
            variant="outline"
          >
            Back
          </Button>
          <Button type="submit">
            Save
          </Button>
        </div>
      </form>
    </div>
  );
};