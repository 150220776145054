import { useEffect, useRef } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Message } from "@/types/messages";
import { useToast } from "@/hooks/use-toast";
import { formatSupabaseMessage } from "@/utils/messageUtils";

export const useInitialMessages = (
  lovedOneId: number | null,
  setMessages: React.Dispatch<React.SetStateAction<Message[]>>
) => {
  const { toast } = useToast();
  const loadingRef = useRef(false);

  useEffect(() => {
    let mounted = true;

    const fetchMessages = async () => {
      if (!lovedOneId || loadingRef.current) return;

      loadingRef.current = true;
      console.log('Starting initial messages fetch for loved one:', lovedOneId);

      try {
        const { data: messagesData, error: messagesError } = await supabase
          .from('messages')
          .select('*')
          .eq('conversation_id', lovedOneId)
          .order('created_at', { ascending: true });

        if (messagesError) {
          console.error('Error fetching messages:', messagesError);
          toast({
            title: "Error",
            description: "Failed to load messages",
            variant: "destructive",
          });
          return;
        }

        if (mounted && messagesData) {
          const formattedMessages = messagesData.map(formatSupabaseMessage);
          console.log(`Successfully loaded ${formattedMessages.length} initial messages for loved one:`, lovedOneId);
          setMessages(formattedMessages);
        }
      } catch (error) {
        console.error('Error in fetchMessages:', error);
        toast({
          title: "Error",
          description: "Failed to load messages",
          variant: "destructive",
        });
      } finally {
        loadingRef.current = false;
      }
    };

    fetchMessages();

    return () => {
      mounted = false;
      loadingRef.current = false;
    };
  }, [lovedOneId, setMessages, toast]);
};