import React from "react";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

interface SubscriptionDialogProps {
  isOpen: boolean;
  onClose: () => void;
  trigger: "chat" | "memories";
}

const SubscriptionDialog = ({ isOpen, onClose, trigger }: SubscriptionDialogProps) => {
  const navigate = useNavigate();

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle className="text-center">
            {trigger === "chat" 
              ? "Continue Chatting with a Subscription" 
              : "Add More Memories with a Subscription"}
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 py-4 text-center">
          <p className="text-gray-600">
            {trigger === "chat"
              ? "To continue this meaningful conversation, please subscribe to our service."
              : "To add more cherished memories to your vault, please subscribe to our service."}
          </p>
          <Button 
            onClick={() => navigate("/subscription")}
            className="w-full bg-facebook-500 hover:bg-facebook-600"
          >
            View Subscription Plans
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SubscriptionDialog;