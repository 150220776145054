import { Navigation } from "@/components/Navigation";
import { AddLovedOneForm } from "@/components/loved-ones/AddLovedOneForm";
import { Footer } from "@/components/Footer";

const AddLovedOne = () => {
  return (
    <div className="min-h-screen flex flex-col bg-cream-50">
      <Navigation />
      <div className="flex-1 flex items-center justify-center">
        <AddLovedOneForm />
      </div>
      <Footer />
    </div>
  );
};

export default AddLovedOne;