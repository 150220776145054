import React from 'react';
import { DragDropContext, DropResult, Droppable } from 'react-beautiful-dnd';
import { Memory } from '@/types/memory';
import DraggableMemory from '../DraggableMemory';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { SortOption } from '@/utils/memorySort';
import MemoryForm from '../MemoryForm';

interface VaultContentProps {
  memories: Memory[];
  showForm: boolean;
  editingMemory?: Memory;
  currentFolder: string;
  onEdit: (memory: Memory) => void;
  onDelete: (id: string) => void;
  onDragEnd: (result: DropResult) => void;
  onSave: (memory: Memory) => void;
  onCancel: () => void;
  sortOption: SortOption;
  onSortChange: (value: SortOption) => void;
  onToggleFavorite: (id: string, isFavorite: boolean) => void;
  folders: string[];
  lovedOneName?: string;
  totalMemoryCount: number;
}

const VaultContent = ({
  memories,
  showForm,
  editingMemory,
  currentFolder,
  onEdit,
  onDelete,
  onDragEnd,
  onSave,
  onCancel,
  sortOption,
  onSortChange,
  onToggleFavorite,
  folders = [],
  totalMemoryCount,
}: VaultContentProps) => {
  console.log('VaultContent rendered:', {
    memoriesCount: memories.length,
    showForm,
    totalMemoryCount
  });
  
  return (
    <section className="animate-fadeIn">
      {showForm && (
        <div className="mb-8">
          <MemoryForm 
            onSave={onSave}
            onCancel={onCancel}
            editingMemory={editingMemory}
            folders={folders}
          />
        </div>
      )}

      {memories.length === 0 && !showForm ? (
        <div className="flex-1 flex flex-col justify-start pt-12 min-h-[calc(100vh-400px)] text-gray-500 text-lg font-medium animate-fadeIn">
          <div className="text-center">
            Add Your First Memory To Begin
          </div>
        </div>
      ) : (
        <>          
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={currentFolder || "ROOT"}>
              {(provided) => (
                <div 
                  ref={provided.innerRef} 
                  {...provided.droppableProps}
                  className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-6"
                >
                  {memories.map((memory, index) => (
                    <DraggableMemory
                      key={memory.id}
                      memory={memory}
                      index={index}
                      onEdit={onEdit}
                      onDelete={onDelete}
                      onToggleFavorite={onToggleFavorite}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </section>
  );
};

export default VaultContent;