import { useState, useEffect } from "react";
import { useToast } from "@/hooks/use-toast";
import { fetchAIResponse } from "@/lib/api-client";
import { supabase } from "@/integrations/supabase/client";
import { Message } from "@/types/messages";
import { formatSupabaseMessage, getRandomDelay, isDuplicateMessage } from "@/utils/messageUtils";
import { useInitialMessages } from "./useInitialMessages";
import { useMessageSubscription } from "./useMessageSubscription";

// Move Set outside component to avoid state management issues
const processedMessageIds = new Set<string>();

export const useMessages = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lovedOneId, setLovedOneId] = useState<number | null>(null);
  const { toast } = useToast();

  useEffect(() => {
    const storedLovedOne = localStorage.getItem("lovedOne");
    if (storedLovedOne) {
      const parsed = JSON.parse(storedLovedOne);
      setLovedOneId(parsed.id);
      console.log('Setting loved one ID:', parsed.id);
    }
  }, []);

  // Add cleanup when component unmounts
  useEffect(() => {
    return () => {
      console.log('Cleaning up messages state and processed IDs');
      setMessages([]);
      processedMessageIds.clear();
    };
  }, []);

  const addMessageToState = (newMessage: Message) => {
    setMessages(prev => {
      // Check if we've already processed this message ID
      if (newMessage.id && processedMessageIds.has(newMessage.id)) {
        console.log('Message already processed:', newMessage.id);
        return prev;
      }

      // Check for duplicates
      if (isDuplicateMessage(newMessage, prev)) {
        console.log('Duplicate message detected:', newMessage);
        return prev;
      }

      // Add ID to processed set if it exists
      if (newMessage.id) {
        processedMessageIds.add(newMessage.id);
      }

      console.log('Adding new message:', newMessage);
      return [...prev, newMessage];
    });
  };

  // Use our hooks for message loading and subscription
  useInitialMessages(lovedOneId, setMessages);
  useMessageSubscription(lovedOneId, addMessageToState);

  const checkForRecentDuplicate = async (content: string, conversationId: number) => {
    const { data: recentMessages } = await supabase
      .from('messages')
      .select('*')
      .eq('conversation_id', conversationId)
      .eq('content', content)
      .eq('is_user', false)
      .gt('created_at', new Date(Date.now() - 5000).toISOString()) // Check last 5 seconds
      .order('created_at', { ascending: false });

    return recentMessages && recentMessages.length > 0;
  };

  const sendMessage = async (newMessage: string) => {
    if (!newMessage.trim() || !lovedOneId) return;

    try {
      // Save user message to Supabase
      const { data: userMessageData, error: userMessageError } = await supabase
        .from('messages')
        .insert({
          content: newMessage,
          is_user: true,
          conversation_id: lovedOneId,
        })
        .select()
        .single();

      if (userMessageError) {
        throw userMessageError;
      }

      // Add user message to local state
      const userMessage = formatSupabaseMessage(userMessageData);
      addMessageToState(userMessage);

      setIsLoading(true);

      // Get AI response
      const aiResponse = await fetchAIResponse(newMessage, lovedOneId, lovedOneId);
      const delay = getRandomDelay(aiResponse.length);
      await new Promise(resolve => setTimeout(resolve, delay));

      // Create temporary message object for immediate display
      const tempMessage: Message = {
        id: `temp-${Date.now()}`,
        text: aiResponse,
        sender: "loved-one",
        timestamp: new Date(),
      };
      addMessageToState(tempMessage);

      // Check if this response was already saved recently
      const isDuplicate = await checkForRecentDuplicate(aiResponse, lovedOneId);
      if (isDuplicate) {
        console.log('Prevented duplicate AI response from being saved:', aiResponse);
        setIsLoading(false);
        return;
      }

      // Save AI response to Supabase
      const { data: aiMessageData, error: aiMessageError } = await supabase
        .from('messages')
        .insert({
          content: aiResponse,
          is_user: false,
          conversation_id: lovedOneId,
        })
        .select()
        .single();

      if (aiMessageError) {
        throw aiMessageError;
      }

      // Update the temporary message with the permanent one from the database
      const responseMessage = formatSupabaseMessage(aiMessageData);
      setMessages(prev => prev.map(msg => 
        msg.id === tempMessage.id ? responseMessage : msg
      ));

    } catch (error) {
      console.error('Error in sendMessage:', error);
      toast({
        title: "Error",
        description: "Failed to send message. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return {
    messages,
    isLoading,
    sendMessage
  };
};