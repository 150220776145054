import { createClient } from '@supabase/supabase-js';
import { supabase as supabaseClient } from '@/integrations/supabase/client';
import { saveMemory } from '@/services/memoryService';
import { saveLovedOne } from '@/services/lovedOneService';

// Use hardcoded values from supabase/client.ts
const supabaseUrl = "https://peacoajtauvoomvanchc.supabase.co";
const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InBlYWNvYWp0YXV2b29tdmFuY2hjIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MzUxNzY3OTgsImV4cCI6MjA1MDc1Mjc5OH0.79_EwlxI9JRcO9wPGwWXvf_G0j9a_RHKT3jVe_Jfgeo";

const supabase = createClient(supabaseUrl, supabaseKey);

export const generateStableId = (input: number | string): number => {
  const MAX_PG_INT = 2147483647; // Maximum PostgreSQL integer value
  
  if (typeof input === 'number') {
    // Take the last 9 digits to ensure it fits in Postgres integer
    return Number(input.toString().slice(-9)) % MAX_PG_INT;
  }
  
  // If it's a string, generate a number from it
  const str = input.toString();
  const numStr = str.slice(-9); // Take last 9 digits
  const num = parseInt(numStr, 10);
  
  // Ensure it's within Postgres integer range
  return num % MAX_PG_INT;
};

export const trainAI = async (samples: string[], lovedOneId: number) => {
  console.log('Training AI with samples:', { samples, lovedOneId });
  
  try {
    const { data: { user }, error: userError } = await supabaseClient.auth.getUser();
    if (userError) {
      console.error('Error getting user:', userError);
      throw new Error('Authentication required');
    }

    if (!user) {
      console.error('No authenticated user found');
      throw new Error('Authentication required');
    }

    const { data: lovedOne, error: verifyError } = await supabaseClient
      .from('loved_ones')
      .select('id, name, user_id')
      .eq('id', lovedOneId)
      .single();

    if (verifyError) {
      console.error('Error verifying loved one:', verifyError);
      throw new Error('Could not verify loved one ownership');
    }

    if (!lovedOne) {
      console.error('No loved one found:', { lovedOneId });
      throw new Error('Could not verify loved one ownership');
    }

    if (lovedOne.user_id !== user.id) {
      console.error('Unauthorized access - user IDs do not match:', {
        lovedOneUserId: lovedOne.user_id,
        currentUserId: user.id
      });
      throw new Error('Could not verify loved one ownership');
    }

    const { error: insertError } = await supabaseClient
      .from('training_samples')
      .insert(
        samples.map(content => ({
          loved_one_id: lovedOneId,
          content,
          sample_type: 'text'
        }))
      );

    if (insertError) {
      console.error('Error storing training samples:', insertError);
      throw insertError;
    }

    console.log('Successfully stored training samples');
    return { success: true };
  } catch (error) {
    console.error('Training error:', error);
    throw error;
  }
};

export const fetchAIResponse = async (message: string, lovedOneId: number | string = 1, conversationId: number | string = 1) => {
  console.log('Starting API call to chat endpoint with original IDs:', {
    lovedOneId,
    conversationId,
    type: typeof lovedOneId
  });
  
  const safeLovedOneId = generateStableId(lovedOneId);
  console.log('Using safe loved one ID:', safeLovedOneId);
  
  const { data: lovedOne, error: lovedOneError } = await supabaseClient
    .from('loved_ones')
    .select('id, name')
    .eq('id', safeLovedOneId)
    .maybeSingle();

  if (lovedOneError) {
    console.error('Error fetching loved one:', lovedOneError);
    throw new Error('Could not verify loved one ID');
  }

  if (!lovedOne) {
    console.error('No loved one found with ID:', safeLovedOneId);
    throw new Error('Invalid loved one ID');
  }

  console.log('Found loved one:', lovedOne);
  
  const { data: memories, error: memoriesError } = await supabaseClient
    .from('memories')
    .select('content, context, created_at')
    .eq('loved_one_id', safeLovedOneId);

  if (memoriesError) {
    console.error('Error fetching memories from Supabase:', memoriesError);
    throw memoriesError;
  }

  console.log('Retrieved memories from Supabase:', memories);

  const formattedMemories = memories?.map((memory: any) => ({
    content: memory.content,
    context: memory.context || '',
    created_at: memory.created_at
  })) || [];

  console.log('Formatted memories for context:', formattedMemories);

  let lastError;
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 1000; // 1 second

  for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
    try {
      console.log(`Making Edge Function request attempt ${attempt + 1}`);
      
      const { data, error } = await supabase.functions.invoke('chat', {
        body: { 
          message,
          lovedOneId: safeLovedOneId,
          conversationId,
          memories: formattedMemories
        }
      });

      if (error) {
        console.error('Edge Function Error:', error);
        throw error;
      }

      console.log('Successful Edge Function response:', data);
      return data.response;
    } catch (error) {
      console.error(`Attempt ${attempt + 1} failed:`, error);
      lastError = error;
      
      if (attempt < MAX_RETRIES - 1) {
        console.log(`Retrying in ${RETRY_DELAY}ms...`);
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      }
    }
  }

  console.error('All retry attempts failed');
  throw lastError || new Error('Failed to get AI response after all retries');
};

export { saveMemory, saveLovedOne };