import React from "react";
import { Button } from "@/components/ui/button";
import { ArrowRight, MessageCircle } from "lucide-react";
import { useNavigate } from "react-router-dom";

interface NextStepButtonProps {
  show: boolean;
}

const NextStepButton = ({ show }: NextStepButtonProps) => {
  const navigate = useNavigate();

  if (!show) return null;

  return (
    <div className="mt-6 space-y-4 pt-4 border-t border-facebook-200">
      <Button
        onClick={() => navigate('/vault')}
        className="w-full bg-facebook-500 hover:bg-facebook-600"
      >
        Go To Memory Vault <ArrowRight className="ml-2 h-4 w-4" />
      </Button>
      <Button
        onClick={() => navigate('/messages')}
        className="w-full bg-facebook-500 hover:bg-facebook-600"
      >
        Start Chatting <MessageCircle className="ml-2 h-4 w-4" />
      </Button>
    </div>
  );
};

export default NextStepButton;