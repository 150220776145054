
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { trackEvent } from "@/utils/analytics";
import { trackMetaPixelEvent } from "@/utils/meta-pixel";

export default function SubscriptionSuccess() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(3);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');
    const selectedPlanId = localStorage.getItem('selectedPlanId');

    // Get the plans data
    const plans = [
      {
        title: "Weekly Pass",
        price: "3.99",
        interval: "week",
        priceId: "price_1QfAk9AzThxgvtztbpbUm4gB",
      },
      {
        title: "Premium Memory Plan",
        price: "9.99",
        interval: "month",
        priceId: "price_1QfAeeAzThxgvtzt1EO7znsr",
      },
      {
        title: "Annual Memory Plan",
        price: "99.99",
        interval: "year",
        priceId: "price_1QfAjGAzThxgvtztwSsmsY1N",
      },
    ];

    if (sessionId && selectedPlanId) {
      const purchasedPlan = plans.find(plan => selectedPlanId === plan.priceId);

      if (purchasedPlan) {
        console.log('Found purchased plan, tracking events:', purchasedPlan);
        
        // Track successful subscription conversion in Meta Pixel
        const purchaseData = {
          currency: 'USD',
          value: parseFloat(purchasedPlan.price),
          content_name: purchasedPlan.title,
          content_type: 'subscription',
          content_ids: [purchasedPlan.priceId],
          transaction_id: sessionId
        };
        
        // Track in Meta Pixel using noScript approach to ensure event fires
        const img = new Image();
        img.src = `https://www.facebook.com/tr?id=1140724357732312&ev=Purchase&cd[currency]=USD&cd[value]=${purchaseData.value}&cd[content_name]=${encodeURIComponent(purchaseData.content_name)}&cd[content_type]=subscription&cd[content_ids]=${purchaseData.content_ids[0]}&cd[transaction_id]=${sessionId}&noscript=1`;
        
        console.log('Tracking Meta Pixel purchase conversion:', purchaseData);
        trackMetaPixelEvent('Purchase', purchaseData);
        
        const subscriptionData = {
          plan_name: purchasedPlan.title,
          subscription_id: sessionId,
          value: parseFloat(purchasedPlan.price),
          currency: 'USD'
        };
        
        // Track subscription complete event with noScript approach
        const subscriptionImg = new Image();
        subscriptionImg.src = `https://www.facebook.com/tr?id=1140724357732312&ev=SubscriptionComplete&cd[plan_name]=${encodeURIComponent(subscriptionData.plan_name)}&cd[subscription_id]=${sessionId}&cd[value]=${subscriptionData.value}&cd[currency]=USD&noscript=1`;
        
        console.log('Tracking Meta Pixel subscription conversion:', subscriptionData);
        trackMetaPixelEvent('SubscriptionComplete', subscriptionData);
        
        // Track in our custom analytics
        trackEvent('subscription', 'subscribe_success', 'Stripe Subscription');
      }
    }

    // Clean up stored plan ID
    localStorage.removeItem('selectedPlanId');

    // Start countdown
    const timer = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(timer);
          navigate('/dashboard', { replace: true });
          return 0;
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [navigate]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center px-4">
      <div className="text-center space-y-4 max-w-md">
        <h1 className="text-3xl font-bold text-gray-900">Thank you for subscribing!</h1>
        <p className="text-gray-600">
          Redirecting you back to the dashboard in {countdown} seconds...
        </p>
      </div>
    </div>
  );
}
