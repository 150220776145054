
// Declare the fbq function type
declare global {
  interface Window {
    fbq: any;
    _fbq: any;
  }
}

export function initializeMetaPixel(pixelId: string) {
  console.log('Initializing Meta Pixel with ID:', pixelId);
  
  // Add Facebook Pixel code with proper type assertions
  (function(f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
    if((f as any).fbq) return; 
    n = (f as any).fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      // Log the pixel event for debugging
      console.log('Meta Pixel event triggered:', arguments);
    };
    if(!(f as any)._fbq) (f as any)._fbq = n;
    n.push = n;
    n.loaded = true;
    n.version = '2.0';
    n.queue = [];
    t = b.createElement(e);
    t.async = true;
    t.src = v;
    s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);
  })(
    window,
    document,
    'script',
    'https://connect.facebook.net/en_US/fbevents.js',
    null,
    null,
    null
  );
  
  // Initialize pixel
  window.fbq('init', pixelId);
  window.fbq('track', 'PageView');
}

// Helper function to ensure fbq is available
export function trackMetaPixelEvent(eventName: string, params?: any) {
  if (window.fbq) {
    console.log(`Tracking Meta Pixel event: ${eventName}`, params);
    window.fbq('track', eventName, params);
  } else {
    console.error('Meta Pixel not initialized');
  }
}
