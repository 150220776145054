import React from 'react';
import { Button } from "@/components/ui/button";
import { UserRound, Image, Upload } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface ProfilePhotoUploadProps {
  lovedOneId: number;
  photoUrl: string | null;
  onPhotoUpdate: (url: string) => void;
  className?: string;
}

const ProfilePhotoUpload = ({ lovedOneId, photoUrl, onPhotoUpdate, className = "" }: ProfilePhotoUploadProps) => {
  const { toast } = useToast();
  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

  const handlePhotoChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      toast({
        title: "File too large",
        description: "Please select an image under 5MB",
        variant: "destructive",
      });
      return;
    }

    try {
      const fileExt = file.name.split('.').pop();
      const filePath = `profile-photos/${lovedOneId}-${Date.now()}.${fileExt}`;

      const { error: uploadError, data } = await supabase.storage
        .from('memories')
        .upload(filePath, file);

      if (uploadError) throw uploadError;

      const { data: { publicUrl } } = supabase.storage
        .from('memories')
        .getPublicUrl(filePath);

      const { error: updateError } = await supabase
        .from('loved_ones')
        .update({ photo_url: publicUrl })
        .eq('id', lovedOneId);

      if (updateError) throw updateError;

      onPhotoUpdate(publicUrl);
      toast({
        title: "Success",
        description: "Profile photo updated successfully",
      });
    } catch (error) {
      console.error('Error uploading photo:', error);
      toast({
        title: "Error",
        description: "Failed to upload profile photo",
        variant: "destructive",
      });
    }
  };

  return (
    <div className={`relative group ${className}`}>
      <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-100 mb-4 mx-auto">
        {photoUrl ? (
          <img 
            src={photoUrl} 
            alt="Profile" 
            className="w-full h-full object-cover"
          />
        ) : (
          <div className="w-full h-full flex flex-col items-center justify-center bg-gray-50">
            <UserRound className="w-12 h-12 text-gray-400 mb-1" />
            <span className="text-xs text-gray-500 text-center px-1">Add Photo</span>
          </div>
        )}
      </div>
      <label className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity cursor-pointer rounded-full">
        <Upload className="w-6 h-6 text-white mb-1" />
        <span className="text-xs text-white">Upload Photo</span>
        <input
          type="file"
          className="hidden"
          accept="image/*"
          onChange={handlePhotoChange}
        />
      </label>
    </div>
  );
};

export default ProfilePhotoUpload;