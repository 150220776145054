import React from 'react';
import { Button } from "@/components/ui/button";
import { Folder, FolderPlus } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface FolderSelectorProps {
  folders: string[];
  currentFolder: string;
  onFolderChange: (folder: string) => void;
  onCreateFolder: () => void;
}

const FolderSelector = ({ folders, currentFolder, onFolderChange, onCreateFolder }: FolderSelectorProps) => {
  return (
    <div className="flex items-center gap-2">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="outline" className="flex items-center gap-2">
            <Folder className="h-4 w-4" />
            {currentFolder || 'All Memories'}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem onClick={() => onFolderChange('')}>
            All Memories
          </DropdownMenuItem>
          {folders.map((folder) => (
            <DropdownMenuItem 
              key={folder}
              onClick={() => onFolderChange(folder)}
            >
              {folder}
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
      <Button
        variant="outline"
        size="icon"
        onClick={onCreateFolder}
        className="text-facebook-500"
      >
        <FolderPlus className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default FolderSelector;