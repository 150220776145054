import { useNavigate } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { Navigation } from "@/components/Navigation";
import { useToast } from "@/hooks/use-toast";
import { Loader2 } from "lucide-react";
import { useState, useEffect } from "react";
import { supabase } from "@/integrations/supabase/client";
import { trackEvent } from "@/utils/analytics";

export default function Subscription() {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  // Handle URL parameters for Stripe return and page refresh
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const sessionId = params.get('session_id');
    const canceled = params.get('canceled');
    
    console.log('Checking URL parameters:', { sessionId, canceled });
    
    if (sessionId) {
      console.log('Stripe session ID found, redirecting to success page');
      navigate(`/subscription-success?session_id=${sessionId}`, { replace: true });
    } else if (canceled) {
      console.log('Subscription was canceled');
      // Track canceled subscription
      trackEvent('subscription', 'subscribe_canceled', 'Stripe Subscription Canceled');
      
      toast({
        title: "Subscription canceled",
        description: "Your subscription was not processed.",
        duration: 5000,
      });
      
      const returnPath = localStorage.getItem('preCheckoutPage') || '/dashboard';
      setTimeout(() => {
        navigate(returnPath, { replace: true });
      }, 2000);
      localStorage.removeItem('preCheckoutPage');
      localStorage.removeItem('selectedPlanId');
    }
  }, [navigate, toast]);

  const handleSubscribe = async (priceId: string) => {
    try {
      setIsLoading(true);
      
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        toast({
          title: "Authentication required",
          description: "Please sign in to subscribe",
          variant: "destructive",
        });
        navigate("/login");
        return;
      }

      // Store the selected plan ID for tracking after successful subscription
      localStorage.setItem('selectedPlanId', priceId);

      // Track subscription attempt
      trackEvent('subscription', 'subscribe_attempt', priceId);

      const response = await supabase.functions.invoke('create-checkout', {
        body: {
          priceId: priceId,
        }
      });

      if (response.error) {
        // Track subscription error
        trackEvent('subscription', 'subscribe_error', response.error.message);
        throw new Error(response.error.message || 'Failed to create checkout session');
      }

      const { data } = response;
      if (data?.url) {
        // Store the current page URL in localStorage before redirecting
        localStorage.setItem('preCheckoutPage', window.location.pathname);
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error: any) {
      console.error('Subscription error:', error);
      // Track subscription error
      trackEvent('subscription', 'subscribe_error', error.message);
      
      toast({
        title: "Error",
        description: error.message || "Failed to start subscription process. Please try again.",
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const plans = [
    {
      title: "Weekly Pass",
      price: "3.99",
      interval: "week",
      priceId: "price_1QfAk9AzThxgvtztbpbUm4gB",
      description: "Short-term access to preserve memories",
    },
    {
      title: "Premium Memory Plan",
      price: "9.99",
      interval: "month",
      priceId: "price_1QfAeeAzThxgvtzt1EO7znsr",
      description: "Preserve and relive your cherished moments with loved ones",
      popular: true,
    },
    {
      title: "Annual Memory Plan",
      price: "99.99",
      interval: "year",
      priceId: "price_1QfAjGAzThxgvtztwSsmsY1N",
      description: "Our best value plan for long-term memory preservation",
      savings: "Save 17%",
    },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <Navigation />
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-6xl mx-auto">
          {plans.map((plan) => (
            <div key={plan.title} className="relative flex flex-col">
              {plan.popular && (
                <div className="absolute -top-3 left-0 right-0 flex justify-center">
                  <span className="bg-facebook-600 text-white text-sm font-medium px-3 py-1 rounded-full">
                    Most Popular
                  </span>
                </div>
              )}
              {plan.savings && (
                <div className="absolute -top-3 left-0 right-0 flex justify-center">
                  <span className="bg-green-500 text-white text-sm font-medium px-3 py-1 rounded-full">
                    {plan.savings}
                  </span>
                </div>
              )}
              <Card className={`w-full flex flex-col ${plan.popular ? 'border-2 border-facebook-600' : ''}`}>
                <CardHeader className="text-center space-y-2">
                  <CardTitle className="text-2xl font-bold min-h-[2.5rem] flex items-center justify-center">
                    {plan.title}
                  </CardTitle>
                  <CardDescription className="min-h-[3rem] flex items-center justify-center">
                    {plan.description}
                  </CardDescription>
                </CardHeader>
                <CardContent className="flex-grow space-y-6">
                  <div className="text-center space-y-2">
                    <div className="flex items-center justify-center gap-1">
                      <span className="text-4xl font-bold">${plan.price}</span>
                      <span className="text-gray-500">/{plan.interval}</span>
                    </div>
                    <p className="text-gray-500">Cancel anytime</p>
                  </div>
                  <div className="space-y-3">
                    <div className="flex items-start gap-2">
                      <svg
                        className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M5 13l4 4L19 7"></path>
                      </svg>
                      <span className="text-left">Unlimited conversations to revisit shared memories</span>
                    </div>
                    <div className="flex items-start gap-2">
                      <svg
                        className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M5 13l4 4L19 7"></path>
                      </svg>
                      <span className="text-left">Advanced memory storage to keep precious moments safe</span>
                    </div>
                    <div className="flex items-start gap-2">
                      <svg
                        className="h-5 w-5 text-green-500 mt-0.5 flex-shrink-0"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M5 13l4 4L19 7"></path>
                      </svg>
                      <span className="text-left">Priority support for a seamless experience</span>
                    </div>
                  </div>
                </CardContent>
                <CardFooter className="mt-auto">
                  <Button 
                    className={`w-full ${plan.popular ? 'bg-facebook-600 hover:bg-facebook-700' : ''}`}
                    onClick={() => handleSubscribe(plan.priceId)}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        Processing...
                      </>
                    ) : (
                      'Subscribe Now'
                    )}
                  </Button>
                </CardFooter>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
