import React from "react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";

interface PhotoUploadHandlerProps {
  onPhotoUploaded: (url: string) => void;
  photoFile: File | null;
}

export const uploadPhoto = async (photoFile: File, toast: any): Promise<string | null> => {
  if (!photoFile) return null;

  try {
    console.log('Starting photo upload process');
    
    // Get current session
    const { data: { session }, error: sessionError } = await supabase.auth.getSession();
    if (sessionError || !session) {
      console.error('Auth error:', sessionError);
      throw new Error('Authentication required');
    }
    
    // Upload to Supabase storage
    const fileExt = photoFile.name.split('.').pop();
    const fileName = `${Math.random()}.${fileExt}`;
    const { data, error } = await supabase.storage
      .from('memories')
      .upload(fileName, photoFile);

    if (error) {
      console.error('Upload error:', error);
      throw error;
    }

    console.log('Photo uploaded successfully:', data);

    // Get public URL
    const { data: { publicUrl } } = supabase.storage
      .from('memories')
      .getPublicUrl(fileName);

    console.log('Generated public URL:', publicUrl);
    return publicUrl;
  } catch (error) {
    console.error('Photo upload error:', error);
    toast({
      title: "Error uploading photo",
      description: error instanceof Error ? error.message : "Please try again",
      variant: "destructive",
    });
    return null;
  }
};

export const PhotoUploadHandler = ({ onPhotoUploaded, photoFile }: PhotoUploadHandlerProps) => {
  const { toast } = useToast();

  const handleUpload = async () => {
    const url = await uploadPhoto(photoFile, toast);
    if (url) {
      onPhotoUploaded(url);
    }
  };

  React.useEffect(() => {
    if (photoFile) {
      handleUpload();
    }
  }, [photoFile]);

  return null;
};