import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Navigation } from "@/components/Navigation";
import { Footer } from "@/components/Footer";
import { SignUpForm } from "@/components/onboarding/SignUpForm";
import { LovedOneForm } from "@/components/onboarding/LovedOneForm";
import { StepIndicator } from "@/components/onboarding/StepIndicator";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { generateStableId } from "@/lib/api-client";
import { supabase } from "@/integrations/supabase/client";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const OnboardingSteps = [
  {
    title: "Sign Up",
    description: "Let's start by creating your account.",
  },
  {
    title: "Welcome",
    description: "What would you like to do?",
  },
  {
    title: "Their Name",
    description: "What was their name?",
  },
  {
    title: "Your Relationship",
    description: "How were you related to them?",
  },
  {
    title: "Final Touch",
    description: "Almost there! Let's personalize your experience.",
  }
];

const Onboarding = () => {
  const [step, setStep] = useState(0);
  const [name, setName] = useState("");
  const [relationship, setRelationship] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lovedOnes, setLovedOnes] = useState<Array<{ id: number; name: string; relationship: string }>>([]);
  const [selectedLovedOne, setSelectedLovedOne] = useState<string>("");
  const [showWelcome, setShowWelcome] = useState(false);
  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    // Only check for new user flag when step is 1 (after signup)
    if (step === 1) {
      const isNewUser = localStorage.getItem("isNewUser") === "true";
      if (isNewUser) {
        console.log("New user detected at step 1, showing welcome dialog");
        setShowWelcome(true);
        localStorage.removeItem("isNewUser");
      }
    }
  }, [step]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('first_name')
          .eq('id', user.id)
          .single();
        
        if (profile?.first_name) {
          setFirstName(profile.first_name);
        }

        // Fetch user's loved ones
        const { data: lovedOnesData } = await supabase
          .from('loved_ones')
          .select('id, name, relationship')
          .eq('user_id', user.id);

        if (lovedOnesData) {
          setLovedOnes(lovedOnesData);
        }
      }
    };

    if (step > 0) {
      fetchUserProfile();
    }
  }, [step]);

  const handleSignUpComplete = (formData: any) => {
    console.log("User sign up data:", formData);
    setStep((prev) => prev + 1);
  };

  const handleComplete = async () => {
    const timestamp = Date.now();
    const stableId = generateStableId(timestamp);

    const lovedOneData = {
      id: stableId,
      name,
      relationship
    };

    try {
      const { error } = await supabase
        .from('loved_ones')
        .insert([lovedOneData]);

      if (error) throw error;

      setLovedOnes([...lovedOnes, lovedOneData]);

      localStorage.setItem("lovedOne", JSON.stringify(lovedOneData));
      localStorage.setItem("lovedOneId", stableId.toString());
      localStorage.setItem("conversationId", stableId.toString());
      localStorage.setItem("isAuthenticated", "true");

      toast({
        title: "Loved one added successfully",
        description: "Would you like to add another loved one?",
        action: (
          <div className="flex gap-2">
            <Button 
              variant="default" 
              onClick={() => {
                setName("");
                setRelationship("");
                setStep(2);
              }}
              className="bg-facebook-600 hover:bg-facebook-700 text-white"
            >
              Add Another
            </Button>
            <Button 
              variant="secondary"
              onClick={() => navigate("/dashboard")}
              className="bg-lavender-600 hover:bg-lavender-700 text-white"
            >
              Continue to Dashboard
            </Button>
          </div>
        ),
        duration: 10000,
      });

    } catch (error) {
      console.error('Error saving loved one:', error);
      toast({
        title: "Error",
        description: "Failed to save your loved one's information. Please try again.",
        variant: "destructive",
      });
    }
  };

  const handleSelectExisting = (lovedOneId: string) => {
    setSelectedLovedOne(lovedOneId);
    const selected = lovedOnes.find(lo => lo.id.toString() === lovedOneId);
    if (selected) {
      localStorage.setItem("lovedOne", JSON.stringify(selected));
      localStorage.setItem("lovedOneId", selected.id.toString());
      localStorage.setItem("conversationId", selected.id.toString());
      navigate("/dashboard");
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-cream-50">
      <Navigation />
      <div className="flex-1 flex items-center justify-center p-4">
        <div className="w-full max-w-md bg-white rounded-xl shadow-sm p-8">
          <StepIndicator currentStep={step} totalSteps={OnboardingSteps.length} />
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-sage-800 mb-2">
              {step === 1 && firstName ? `Welcome, ${firstName}!` : OnboardingSteps[step].title}
            </h2>
            <p className="text-sage-600">{step === 1 ? "Let's begin your journey together" : OnboardingSteps[step].description}</p>
          </div>

          {step === 0 ? (
            <SignUpForm onNext={handleSignUpComplete} />
          ) : step === 1 ? (
            <div className="space-y-4">
              <Button
                onClick={() => setStep(2)}
                className="w-full bg-facebook-600 hover:bg-facebook-700 text-white"
              >
                Add a Loved One
              </Button>

              {lovedOnes.length > 0 && (
                <div className="space-y-2">
                  <p className="text-sm text-center text-gray-600">or select an existing loved one</p>
                  <Select onValueChange={handleSelectExisting} value={selectedLovedOne}>
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Choose a loved one" />
                    </SelectTrigger>
                    <SelectContent>
                      {lovedOnes.map((lo) => (
                        <SelectItem key={lo.id} value={lo.id.toString()}>
                          {lo.name} ({lo.relationship})
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
              )}
            </div>
          ) : step === 2 || step === 3 ? (
            <LovedOneForm
              step={step}
              name={name}
              relationship={relationship}
              onNameChange={setName}
              onRelationshipChange={setRelationship}
              onNext={step === 3 ? handleComplete : () => setStep((prev) => prev + 1)}
              onBack={() => setStep((prev) => prev - 1)}
            />
          ) : (
            <div className="text-center">
              <p className="text-sage-600 mb-4">
                {firstName ? `${firstName}, you're` : "You're"} all set! We'll help you remember and honor your loved one.
              </p>
              <button
                onClick={handleComplete}
                className="btn-primary w-full bg-facebook-600 hover:bg-facebook-700 text-white"
              >
                Start Your Journey
              </button>
            </div>
          )}
        </div>
      </div>
      <Footer />

      <Dialog open={showWelcome} onOpenChange={setShowWelcome}>
        <DialogContent className="sm:max-w-[500px] bg-white p-6">
          <DialogHeader className="space-y-4">
            <DialogTitle className="text-2xl font-semibold">
              Welcome to MemorySpace! 🌟
            </DialogTitle>
            <DialogDescription className="space-y-6">
              <div className="flex items-start gap-3">
                <span className="flex-shrink-0 text-xl">1️⃣</span>
                <span className="text-left">Add a loved one.</span>
              </div>
              <div className="flex items-start gap-3">
                <span className="flex-shrink-0 text-xl">2️⃣</span>
                <span className="text-left">Create their memory vault with photos and stories.</span>
              </div>
              <div className="flex items-start gap-3">
                <span className="flex-shrink-0 text-xl">3️⃣</span>
                <span className="text-left">Upload their messages to feel connected through lifelike chats.</span>
              </div>
            </DialogDescription>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Onboarding;
