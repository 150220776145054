import React from "react";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Plus, Trash } from "lucide-react";

interface TextSampleFormProps {
  textSamples: string[];
  onSampleChange: (index: number, value: string) => void;
  onAddSample: () => void;
  onRemoveSample: (index: number) => void;
  onSubmit: (e: React.FormEvent) => void;
  onCancel: () => void;
}

const TextSampleForm = ({
  textSamples,
  onSampleChange,
  onAddSample,
  onRemoveSample,
  onSubmit,
  onCancel,
}: TextSampleFormProps) => {
  return (
    <form onSubmit={onSubmit} className="space-y-4">
      {textSamples.map((sample, index) => (
        <div key={index} className="relative">
          <Textarea
            value={sample}
            onChange={(e) => onSampleChange(index, e.target.value)}
            placeholder="Paste a message that shows their writing style..."
            className="min-h-[100px]"
          />
          {textSamples.length > 1 && (
            <Button
              type="button"
              variant="ghost"
              size="icon"
              className="absolute top-2 right-2"
              onClick={() => onRemoveSample(index)}
            >
              <Trash className="h-4 w-4 text-red-500" />
            </Button>
          )}
        </div>
      ))}

      <Button
        type="button"
        variant="outline"
        onClick={onAddSample}
        className="w-full"
      >
        <Plus className="h-4 w-4 mr-2" /> Add Another Example
      </Button>

      <div className="flex justify-end gap-2 pt-4">
        <Button
          type="button"
          variant="outline"
          onClick={onCancel}
          className="bg-white hover:bg-gray-50"
        >
          Cancel
        </Button>
        <Button 
          type="submit"
          className="bg-facebook-500 hover:bg-facebook-600 text-white font-medium"
        >
          Save Examples
        </Button>
      </div>
    </form>
  );
};

export default TextSampleForm;