import { Navigation } from "@/components/Navigation";

const Terms = () => {
  return (
    <div className="min-h-screen flex flex-col bg-cream-50">
      <Navigation />
      <main className="flex-1 container mx-auto px-4 py-8 max-w-4xl">
        <h1 className="text-3xl font-bold mb-8">Terms and Conditions</h1>
        <div className="prose max-w-none">
          <p className="text-sm text-gray-600 mb-6">Effective Date: December 29, 2024</p>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">1. Acceptance of Terms</h2>
            <p>Welcome to MemorySpace.co (the "Platform"). By accessing or using the Platform, you agree to comply with these Terms and Conditions (the "Terms"). If you do not agree, you must not use the Platform.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">2. Platform Description</h2>
            <p>MemorySpace.co is a platform that allows users to store and share memories of loved ones, including photos, text, and other content. The Platform also offers AI-generated tools to simulate text-based interactions that help users feel connected to loved ones who have passed away. MemorySpace.co is designed for personal use, emotional reflection, and memory preservation purposes only.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">3. User Responsibilities</h2>
            <p>By using the Platform, you agree to:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Use the Platform responsibly and respectfully.</li>
              <li>Ensure that all content you upload, including photos and text, complies with laws and does not infringe upon the rights of others.</li>
              <li>Obtain consent from others before uploading content that includes them.</li>
              <li>Use AI-generated features only for personal purposes and acknowledge that simulations are not real messages from deceased individuals.</li>
              <li>Respect the privacy of other users and their shared content.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">4. Content Ownership and Licensing</h2>
            <ul className="list-disc pl-6 mb-4">
              <li>You retain ownership of any content you upload to the Platform.</li>
              <li>By uploading content, you grant MemorySpace.co a non-exclusive, royalty-free license to store, display, and share your content with users you invite or approve.</li>
              <li>MemorySpace.co reserves the right to remove any content that violates these Terms or is deemed inappropriate.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">5. Assumption of Risk and Emotional Impact</h2>
            <p>By using the Platform, you acknowledge and accept:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>The emotional and psychological effects that may arise from interacting with AI-generated simulations or reviewing stored memories.</li>
              <li>The Platform is not a substitute for professional mental health support, and users are encouraged to seek counseling if needed.</li>
              <li>Any use of AI features is voluntary and MemorySpace.co does not guarantee accuracy, authenticity, or satisfaction from the generated interactions.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">6. Privacy and Data Security</h2>
            <p>Your privacy is important to us. Please refer to our Privacy Policy for detailed information on how we collect, store, and protect your data. By using the Platform, you agree to the practices outlined in the Privacy Policy.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">7. Limitation of Liability</h2>
            <p>To the fullest extent permitted by law, MemorySpace.co and its affiliates disclaim any liability for:</p>
            <ul className="list-disc pl-6 mb-4">
              <li>Emotional distress, data loss, or other impacts related to the Platform's use.</li>
              <li>Unauthorized access to or misuse of your content.</li>
              <li>Errors, defects, or service interruptions.</li>
            </ul>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">8. Indemnification</h2>
            <p>You agree to indemnify and hold harmless MemorySpace.co, its officers, employees, and affiliates from any claims, damages, losses, or expenses (including legal fees) arising from your use of the Platform, content uploads, or violations of these Terms.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">9. Termination of Access</h2>
            <p>MemorySpace.co reserves the right to suspend or terminate your access if you violate these Terms or engage in behavior deemed inappropriate or unlawful.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">10. Modifications to Terms</h2>
            <p>We reserve the right to modify these Terms at any time. Changes will be posted, and your continued use of the Platform signifies acceptance of the updated Terms.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">11. Governing Law and Dispute Resolution</h2>
            <p>These Terms are governed by the laws of California, United States of America. Any disputes will be resolved through binding arbitration in California in accordance with the rules of the American Arbitration Association.</p>
          </section>

          <section className="mb-8">
            <h2 className="text-xl font-semibold mb-4">12. Contact Information</h2>
            <p>For questions or concerns regarding these Terms, please contact us at support@memoryspace.co.</p>
          </section>

          <p className="mt-8 text-sm text-gray-600">By using MemorySpace.co, you confirm that you have read, understood, and agreed to these Terms and Conditions.</p>
        </div>
      </main>
    </div>
  );
};

export default Terms;