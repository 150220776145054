import React, { useEffect, useState, useRef } from "react";
import { Button } from "@/components/ui/button";
import { useToast } from "@/hooks/use-toast";
import { Upload } from "lucide-react";
import MessageBubble from "@/components/MessageBubble";
import TypingIndicator from "@/components/TypingIndicator";
import UploadModal from "@/components/UploadModal";
import { Navigation } from "@/components/Navigation";
import MessageInput from "@/components/MessageInput";
import { useMessages } from "@/hooks/useMessages";
import SubscriptionDialog from "@/components/SubscriptionDialog";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "@/hooks/useSubscription";

interface LovedOne {
  id: number;
  name: string;
  relationship: string;
  user_id: string;
}

const Messages = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [lovedOne, setLovedOne] = useState<LovedOne | null>(null);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const { messages, isLoading, sendMessage } = useMessages();
  const { toast } = useToast();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { isSubscribed } = useSubscription(); // Use the user's subscription status

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    const verifyAccess = async () => {
      try {
        // Check authentication
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError || !session?.user) {
          console.error('Session error:', sessionError);
          toast({
            title: "Authentication required",
            description: "Please sign in to continue",
            variant: "destructive",
          });
          navigate("/login");
          return;
        }

        // Get and verify loved one
        const storedLovedOne = localStorage.getItem("lovedOne");
        if (!storedLovedOne) {
          toast({
            title: "No loved one selected",
            description: "Please complete the onboarding process",
            variant: "destructive",
          });
          navigate("/onboarding");
          return;
        }

        const parsedLovedOne = JSON.parse(storedLovedOne);
        
        // Verify ownership
        const { data: verifiedLovedOne, error: verifyError } = await supabase
          .from('loved_ones')
          .select('*')
          .eq('id', parsedLovedOne.id)
          .eq('user_id', session.user.id)
          .single();

        if (verifyError || !verifiedLovedOne) {
          console.error('Verification error or unauthorized access:', verifyError);
          toast({
            title: "Access denied",
            description: "You don't have permission to access this conversation",
            variant: "destructive",
          });
          localStorage.removeItem("lovedOne");
          navigate("/dashboard");
          return;
        }

        setLovedOne(verifiedLovedOne);
      } catch (error) {
        console.error('Access verification error:', error);
        toast({
          title: "Error",
          description: "An error occurred while verifying access",
          variant: "destructive",
        });
        navigate("/login");
      }
    };

    verifyAccess();
  }, [navigate, toast]);

  const handleSendMessage = async (message: string) => {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) return;

    // Count AI responses (non-user messages) for this conversation
    const aiMessageCount = messages.filter(msg => msg.sender === "loved-one").length;
    console.log('Current AI message count:', aiMessageCount);

    // Show subscription dialog after 3 AI responses if user is not subscribed
    if (aiMessageCount >= 3 && !isSubscribed) {
      console.log('Showing subscription dialog - user not subscribed:', { aiMessageCount, isSubscribed });
      setShowSubscriptionDialog(true);
      return;
    }

    await sendMessage(message);
  };

  if (!lovedOne) {
    return null;
  }

  return (
    <div className="min-h-screen flex flex-col bg-white animate-fadeIn">
      <Navigation />
      <div className="flex-1 max-w-2xl mx-auto h-[calc(100vh-8rem)] flex flex-col w-full px-0 sm:px-4">
        <div className="bg-[#F2F2F7] px-4 py-3 flex justify-between items-center border-b border-gray-200">
          <h2 className="text-black font-semibold text-lg">
            {lovedOne.name}
          </h2>
          <Button
            variant="ghost"
            size="icon"
            onClick={() => setShowUploadModal(true)}
            className="text-[#007AFF] hover:text-[#007AFF]/80"
          >
            <Upload className="h-5 w-5" />
          </Button>
        </div>

        <div className="bg-[#F1F0FB] px-4 py-3 border-b border-gray-200">
          <p className="text-[#403E43] text-sm text-center leading-relaxed">
            This is a space for connection and remembrance. While these messages are generated by AI to help you feel close to {lovedOne.name}, they are meant to support your healing journey, not to replicate their exact presence. We hope this brings you comfort and peace.
          </p>
        </div>

        <div className="flex-1 overflow-y-auto px-4 py-4 space-y-2 bg-white">
          {messages.map((message) => (
            <MessageBubble key={message.id} message={message} />
          ))}
          {isLoading && <TypingIndicator />}
          <div ref={messagesEndRef} />
        </div>

        <MessageInput onSend={handleSendMessage} disabled={isLoading} />
      </div>

      <UploadModal
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
      />

      <SubscriptionDialog
        isOpen={showSubscriptionDialog}
        onClose={() => setShowSubscriptionDialog(false)}
        trigger="chat"
      />
    </div>
  );
};

export default Messages;