import { MessageCircle, BookMarked, Heart } from "lucide-react";

const features = [
  {
    icon: Heart,
    title: "Heal at Your Own Pace",
    description: "A compassionate space designed to support your grieving journey. Connect with your memories and feelings in a way that feels right for you."
  },
  {
    icon: BookMarked,
    title: "Preserve Precious Memories",
    description: "Create a digital sanctuary for your cherished memories. Save photos, stories, and meaningful moments in your Memory Vault that can be shared with others, ensuring their legacy lives on."
  },
  {
    icon: MessageCircle,
    title: "Feel Connected Through Messages",
    description: "Experience meaningful interactions that feel familiar and comforting. Our AI-powered system learns your loved one's unique communication style to help maintain that special connection."
  }
];

export const FeaturesSection = () => {
  return (
    <section className="container mx-auto px-4 py-16 bg-[#F6F6F7]">
      <h2 className="text-3xl font-semibold text-lavender-800 text-center mb-16 animate-fadeIn">
        A Thoughtful Space for Remembrance
      </h2>
      <div className="grid md:grid-cols-3 gap-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="bg-white/90 backdrop-blur-sm p-8 rounded-xl shadow-sm border border-lavender-100 text-center transform hover:scale-105 transition-all duration-300 animate-slideUp"
            style={{ animationDelay: `${index * 100}ms` }}
          >
            <div className="inline-flex p-4 rounded-full bg-gradient-to-br from-cream-100 to-lavender-100 text-lavender-600 mb-6">
              <feature.icon size={28} />
            </div>
            <h3 className="text-2xl font-semibold text-lavender-800 mb-4">
              {feature.title}
            </h3>
            <p className="text-lavender-600 leading-relaxed">
              {feature.description}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};