import { Pencil, Trash2, Calendar, Heart } from "lucide-react";
import { Button } from "@/components/ui/button";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Memory } from "@/types/memory";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";

interface MemoryCardProps {
  memory: Memory;
  onEdit: (memory: Memory) => void;
  onDelete: (id: string) => void;
  onToggleFavorite: (id: string, isFavorite: boolean) => void;
}

const months = [
  "January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const MemoryCard = ({ memory, onEdit, onDelete, onToggleFavorite }: MemoryCardProps) => {
  const formattedDate = new Date(memory.date).toLocaleDateString();
  const hasMemoryDate = memory.memory_month || memory.memory_year;
  
  const getMemoryDateString = () => {
    if (!hasMemoryDate) return null;
    
    const parts = [];
    if (memory.memory_month) {
      parts.push(months[memory.memory_month - 1]);
    }
    if (memory.memory_year) {
      parts.push(memory.memory_year);
    }
    return parts.join(" ");
  };

  return (
    <div className="bg-white rounded-xl shadow-sm hover:shadow-md transition-all duration-200 overflow-hidden border border-gray-100 group h-[300px] flex flex-col">
      <div className="p-4 flex flex-col flex-grow">
        <div className="flex justify-between items-start gap-2 mb-2">
          <h3 className="text-lg font-semibold text-gray-900 line-clamp-1">{memory.title}</h3>
          <span className="text-xs text-gray-500 whitespace-nowrap">{formattedDate}</span>
        </div>
        
        {hasMemoryDate && (
          <div className="flex items-center gap-1.5 text-gray-600 text-xs mb-2 bg-gray-50 py-1 px-2 rounded-md w-fit">
            <Calendar className="h-3 w-3" />
            <span>{getMemoryDateString()}</span>
          </div>
        )}

        {memory.photoUrl && (
          <Dialog>
            <DialogTrigger asChild>
              <div className="relative w-full h-32 cursor-pointer mb-2">
                <img 
                  src={memory.photoUrl} 
                  alt={memory.title}
                  className="w-full h-full object-cover rounded-md"
                />
              </div>
            </DialogTrigger>
            <DialogContent className="max-w-4xl w-[90vw] h-[90vh] flex items-center justify-center">
              <img
                src={memory.photoUrl}
                alt={memory.title}
                className="max-w-full max-h-full object-contain"
              />
              {memory.photoCaption && (
                <div className="absolute bottom-4 left-4 right-4 bg-black bg-opacity-50 text-white p-2 text-sm rounded">
                  {memory.photoCaption}
                </div>
              )}
            </DialogContent>
          </Dialog>
        )}
        
        <p className="text-gray-600 text-sm line-clamp-3 mb-4 flex-grow">
          {memory.description}
        </p>
        
        <div className="mt-auto">
          {memory.addedByName && (
            <div className="text-xs text-gray-500 mb-2">
              Added by {memory.addedByName}
            </div>
          )}
          
          <div className="flex gap-2 justify-end opacity-0 group-hover:opacity-100 transition-opacity">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => onToggleFavorite(memory.id, !memory.isFavorite)}
              className={`${
                memory.isFavorite 
                  ? 'text-red-600 hover:text-red-700 hover:bg-red-50' 
                  : 'text-gray-600 hover:text-red-600 hover:bg-red-50'
              }`}
            >
              <Heart 
                className={`h-4 w-4 ${memory.isFavorite ? 'fill-current' : ''}`} 
              />
            </Button>

            <Button
              variant="ghost"
              size="sm"
              onClick={() => onEdit(memory)}
              className="text-gray-600 hover:text-facebook-600 hover:bg-facebook-50"
            >
              <Pencil className="h-4 w-4" />
            </Button>
            
            <AlertDialog>
              <AlertDialogTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="text-red-600 hover:bg-red-50"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
              </AlertDialogTrigger>
              <AlertDialogContent>
                <AlertDialogHeader>
                  <AlertDialogTitle>Delete Memory</AlertDialogTitle>
                  <AlertDialogDescription>
                    Are you sure you want to delete this memory? This action cannot be undone.
                  </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <AlertDialogAction
                    onClick={() => onDelete(memory.id)}
                    className="bg-red-600 hover:bg-red-700"
                  >
                    Delete
                  </AlertDialogAction>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialog>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemoryCard;