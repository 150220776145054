import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Search } from "lucide-react";
import FolderSelector from "../FolderSelector";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { SortOption } from '@/utils/memorySort';

interface VaultActionsProps {
  onNewMemory: () => void;
  onCreateFolder: () => void;
  searchQuery: string;
  onSearchChange: (value: string) => void;
  folders: string[];
  currentFolder: string;
  onFolderChange: (folder: string) => void;
  showFavorites: boolean;
  onToggleFavorites: () => void;
  sortOption: string;
  onSortChange: (value: string) => void;
}

const VaultActions = ({
  onNewMemory,
  onCreateFolder,
  searchQuery,
  onSearchChange,
  folders,
  currentFolder,
  onFolderChange,
  sortOption,
  onSortChange,
}: VaultActionsProps) => {
  console.log('VaultActions rendered with sort option:', sortOption);

  const handleSortChange = (value: string) => {
    console.log('Sort option changed to:', value);
    onSortChange(value);
  };

  return (
    <section className="hidden md:block bg-white rounded-lg p-6 shadow-sm mb-8 animate-fadeIn">
      <div className="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between">
        <div className="flex flex-col gap-2 sm:flex-row sm:items-center">
          <Button
            onClick={onNewMemory}
            className="bg-facebook-500 hover:bg-facebook-600 text-white w-full sm:w-auto"
          >
            Add New Memory
          </Button>
          <FolderSelector
            folders={folders}
            currentFolder={currentFolder}
            onFolderChange={onFolderChange}
            onCreateFolder={onCreateFolder}
          />
        </div>
        <div className="flex items-center gap-4">
          <div className="relative w-full sm:w-64">
            <Search className="absolute left-2 top-2.5 h-4 w-4 text-gray-400" />
            <Input
              placeholder="Search memories..."
              value={searchQuery}
              onChange={(e) => onSearchChange(e.target.value)}
              className="pl-8 w-full bg-white"
            />
          </div>
          <Select
            value={sortOption}
            onValueChange={handleSortChange}
          >
            <SelectTrigger className="w-[200px] bg-white">
              <SelectValue placeholder="Sort by" />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="dateAdded-newest">Newest First</SelectItem>
              <SelectItem value="dateAdded-oldest">Oldest First</SelectItem>
              <SelectItem value="memoryDate-newest">Memory Date (Newest)</SelectItem>
              <SelectItem value="memoryDate-oldest">Memory Date (Oldest)</SelectItem>
              <SelectItem value="favorites">Favorites</SelectItem>
            </SelectContent>
          </Select>
        </div>
      </div>
    </section>
  );
};

export default VaultActions;