import React from "react";
import { format } from "date-fns";

interface Message {
  id: string;
  text: string;
  sender: "user" | "loved-one";
  timestamp: Date;
  isLoading?: boolean;
}

interface MessageBubbleProps {
  message: Message;
}

const MessageBubble = ({ message }: MessageBubbleProps) => {
  const isUser = message.sender === "user";

  return (
    <div
      className={`flex ${isUser ? "justify-end" : "justify-start"} animate-slideUp mb-2`}
    >
      <div
        className={`max-w-[70%] rounded-[22px] px-4 py-2 ${
          isUser
            ? "bg-[#007AFF] text-white rounded-br-md"
            : "bg-[#E9E9EB] text-black rounded-bl-md"
        } ${message.isLoading ? "opacity-50" : ""}`}
      >
        <p className="text-[15px] leading-5">{message.text}</p>
        <span className={`text-[11px] ${isUser ? "text-white/70" : "text-black/50"} mt-1 block`}>
          {format(message.timestamp, "HH:mm")}
        </span>
      </div>
    </div>
  );
};

export default MessageBubble;