
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";
import Index from "@/pages/Index";
import Login from "@/pages/Login";
import Onboarding from "@/pages/Onboarding";
import Messages from "@/pages/Messages";
import Training from "@/pages/Training";
import Dashboard from "@/pages/Dashboard";
import LovedOnesOptions from "@/pages/LovedOnesOptions";
import Vault from "@/pages/Vault";
import SharedMemories from "@/pages/SharedMemories";
import AddLovedOne from "@/pages/AddLovedOne";
import Terms from "@/pages/Terms";
import Privacy from "@/pages/Privacy";
import Subscription from "@/pages/Subscription";
import SubscriptionSuccess from "@/pages/SubscriptionSuccess";
import ResetPassword from "@/pages/ResetPassword";
import { Toaster } from "@/components/ui/toaster";
import { initGA, trackPageView } from "@/utils/analytics";
import { initializeMetaPixel } from "@/utils/meta-pixel";

const RouteChangeTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    initGA();
    // Initialize Meta Pixel with your pixel ID
    initializeMetaPixel('1140724357732312');
  }, []);

  return (
    <Router>
      <RouteChangeTracker />
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/onboarding" element={<Onboarding />} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/training" element={<Training />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/loved-ones-options" element={<LovedOnesOptions />} />
        <Route path="/vault" element={<Vault />} />
        <Route path="/shared-memories/:id" element={<SharedMemories />} />
        <Route path="/add-loved-one" element={<AddLovedOne />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/subscription" element={<Subscription />} />
        <Route path="/subscription-success" element={<SubscriptionSuccess />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <Toaster />
    </Router>
  );
}

export default App;
