import React from 'react';
import { Button } from "@/components/ui/button";
import { Image } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import ImagePreview from './ImagePreview';

interface PhotoUploadProps {
  photoPreview: string;
  onPhotoChange: (file: File) => void;
  onRemovePhoto: () => void;
}

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB

const PhotoUpload = ({ photoPreview, onPhotoChange, onRemovePhoto }: PhotoUploadProps) => {
  const { toast } = useToast();

  const handlePhotoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE) {
        toast({
          title: "File too large",
          description: "Please select an image under 5MB",
          variant: "destructive",
        });
        return;
      }
      onPhotoChange(file);
    }
  };

  return (
    <div className="relative">
      {photoPreview ? (
        <ImagePreview 
          photoPreview={photoPreview} 
          onRemovePhoto={onRemovePhoto} 
        />
      ) : (
        <div className="flex items-center justify-center w-full">
          <label className="w-full flex flex-col items-center justify-center h-32 border-2 border-dashed border-sage-300 rounded-lg cursor-pointer hover:bg-sage-50">
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <Image className="w-8 h-8 mb-2 text-sage-500" />
              <p className="text-sm text-sage-500 mb-1">Click to upload photo</p>
              <p className="text-xs text-sage-400">Maximum file size: 5MB</p>
            </div>
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handlePhotoChange}
            />
          </label>
        </div>
      )}
    </div>
  );
};

export default PhotoUpload;