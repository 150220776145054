import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navigation } from "@/components/Navigation";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { supabase } from "@/integrations/supabase/client";
import { Footer } from "@/components/Footer";
import { useToast } from "@/components/ui/use-toast";
import { Loader2, Plus, UserPlus } from "lucide-react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface LovedOne {
  id: number;
  name: string;
  relationship: string;
}

const Dashboard = () => {
  const navigate = useNavigate();
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const [lovedOneName, setLovedOneName] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [lovedOnes, setLovedOnes] = useState<LovedOne[]>([]);
  const [selectedLovedOne, setSelectedLovedOne] = useState<string>("");
  const [hasSamples, setHasSamples] = useState(false);
  const { toast } = useToast();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) throw sessionError;
        
        if (!session?.user) {
          navigate("/login");
          return;
        }

        // Fetch loved ones
        const { data: lovedOnesData, error: lovedOnesError } = await supabase
          .from('loved_ones')
          .select('*')
          .eq('user_id', session.user.id);

        if (lovedOnesError) throw lovedOnesError;

        setLovedOnes(lovedOnesData || []);

        // Check if there's a stored loved one
        const storedLovedOne = localStorage.getItem("lovedOne");
        const hasSeenWelcome = localStorage.getItem("hasSeenWelcome");
        
        if (storedLovedOne && !hasSeenWelcome) {
          const lovedOne = JSON.parse(storedLovedOne);
          setLovedOneName(lovedOne.name);
          setSelectedLovedOne(lovedOne.id.toString());
          setShowWelcomeDialog(true);
          localStorage.setItem("hasSeenWelcome", "true");
        } else if (lovedOnesData && lovedOnesData.length > 0) {
          setSelectedLovedOne(lovedOnesData[0].id.toString());
          checkTrainingSamples(lovedOnesData[0].id);
        }

        setLoading(false);
      } catch (error: any) {
        console.error('Auth check error:', error);
        setError(error.message);
        setLoading(false);
        toast({
          title: "Authentication Error",
          description: "Please sign in to continue",
          variant: "destructive",
        });
        navigate("/login");
      }
    };

    checkAuth();
  }, [navigate, toast]);

  const checkTrainingSamples = async (lovedOneId: number) => {
    const { data: samples, error } = await supabase
      .from('training_samples')
      .select('id')
      .eq('loved_one_id', lovedOneId)
      .limit(1);

    if (error) {
      console.error('Error checking training samples:', error);
      return;
    }

    setHasSamples(samples && samples.length > 0);
  };

  const handleLovedOneChange = async (value: string) => {
    setSelectedLovedOne(value);
    const selectedOne = lovedOnes.find(lo => lo.id.toString() === value);
    if (selectedOne) {
      localStorage.setItem("lovedOne", JSON.stringify(selectedOne));
      localStorage.setItem("lovedOneId", value);
      localStorage.setItem("conversationId", value);
      await checkTrainingSamples(selectedOne.id);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-cream-50 flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-sage-600" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-cream-50 flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-xl font-semibold text-sage-800 mb-2">Something went wrong</h2>
          <p className="text-sage-600">{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen flex flex-col bg-cream-50">
      <Navigation />
      <main className="flex-1 container mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold text-sage-800">Dashboard</h1>
          <div className="flex items-center gap-4">
            <Select value={selectedLovedOne} onValueChange={handleLovedOneChange}>
              <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Select a loved one" />
              </SelectTrigger>
              <SelectContent>
                {lovedOnes.map((lo) => (
                  <SelectItem key={lo.id} value={lo.id.toString()}>
                    {lo.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Button onClick={() => navigate("/add-loved-one")} variant="outline">
              <UserPlus className="h-4 w-4 mr-2" />
              Add New
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold text-sage-800 mb-4">Memory Vault</h2>
            <p className="text-sage-600 mb-4">Store and organize precious memories, photos, and stories.</p>
            <Button onClick={() => navigate("/vault")} className="w-full">
              View Vault
            </Button>
          </div>
          
          <div className="bg-white p-6 rounded-lg shadow-sm">
            <h2 className="text-xl font-semibold text-sage-800 mb-4">Training</h2>
            <p className="text-sage-600 mb-4">Share message samples to enhance the conversation experience.</p>
            <Button onClick={() => navigate("/training")} className="w-full">
              Start Training
            </Button>
          </div>
          
          <div className={`bg-white p-6 rounded-lg shadow-sm ${!hasSamples ? 'opacity-50' : ''}`}>
            <h2 className="text-xl font-semibold text-sage-800 mb-4">Conversations</h2>
            <p className="text-sage-600 mb-4">
              {hasSamples 
                ? "Have meaningful conversations and stay connected."
                : "Add message samples in training first to enable chat."}
            </p>
            <Button 
              onClick={() => navigate("/messages")} 
              className="w-full"
              disabled={!hasSamples}
            >
              Start Chat
            </Button>
          </div>
        </div>
      </main>
      <Footer />

      <Dialog open={showWelcomeDialog} onOpenChange={setShowWelcomeDialog}>
        <DialogContent className="sm:max-w-[500px]">
          <DialogHeader>
            <DialogTitle className="text-2xl font-semibold text-sage-800">
              Welcome to Your Journey
            </DialogTitle>
            <DialogDescription className="text-sage-600 mt-4">
              Next, you can start adding memories for {lovedOneName}, or go to the training section to share messages from {lovedOneName}. Sharing message samples is so that you can feel connected to them by chat.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter className="flex flex-col sm:flex-row gap-3 mt-6">
            <Button
              variant="outline"
              onClick={() => {
                setShowWelcomeDialog(false);
                navigate("/vault");
              }}
              className="w-full sm:w-auto"
            >
              Add Memories
            </Button>
            <Button
              onClick={() => {
                setShowWelcomeDialog(false);
                navigate("/training");
              }}
              className="w-full sm:w-auto"
            >
              Share Messages
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Dashboard;