import { Button } from "@/components/ui/button";
import { useNavigate } from "react-router-dom";

export const CTASection = () => {
  const navigate = useNavigate();
  
  return (
    <section className="bg-gradient-to-r from-cream-50 to-lavender-50 py-20">
      <div className="container mx-auto px-4 text-center">
        <h2 className="text-4xl font-bold text-lavender-800 mb-6">
          Begin Your Healing Journey Today
        </h2>
        <p className="text-lg text-lavender-600 mb-8 max-w-2xl mx-auto">
          Create a meaningful space to honor your loved one's memory and maintain that special connection.
        </p>
        <Button
          onClick={() => navigate("/onboarding")}
          size="lg"
          className="text-lg bg-facebook-500 hover:bg-facebook-600 hover:shadow-lg transition-all duration-300"
        >
          Get Started
        </Button>
      </div>
    </section>
  );
};