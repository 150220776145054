import { Navigation } from "@/components/Navigation";
import UploadModal from "@/components/UploadModal";
import { Button } from "@/components/ui/button";
import { useState, useEffect } from "react";
import { Eye } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { useNavigate } from "react-router-dom";
import SavedSamplesList from "@/components/training/SavedSamplesList";
import NextStepButton from "@/components/training/NextStepButton";

const Training = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [savedSamples, setSavedSamples] = useState<string[]>([]);
  const [lovedOne, setLovedOne] = useState<any>(null);
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthAndLovedOne = async () => {
      try {
        // Check authentication
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw sessionError;
        }

        if (!session?.user) {
          console.log('No authenticated session found');
          toast({
            title: "Authentication required",
            description: "Please sign in to access this page",
            variant: "destructive",
          });
          navigate("/login");
          return;
        }

        // Get loved one from localStorage
        const storedLovedOne = JSON.parse(localStorage.getItem("lovedOne") || "null");
        if (!storedLovedOne?.id) {
          console.log('No loved one found in localStorage');
          toast({
            title: "No loved one selected",
            description: "Please complete the onboarding process first",
            variant: "destructive",
          });
          navigate("/onboarding");
          return;
        }

        // Verify ownership of loved one
        const { data: verifiedLovedOne, error: verifyError } = await supabase
          .from('loved_ones')
          .select('*')
          .eq('id', storedLovedOne.id)
          .eq('user_id', session.user.id)
          .single();

        if (verifyError || !verifiedLovedOne) {
          console.error('Verification error or unauthorized access:', verifyError);
          toast({
            title: "Access denied",
            description: "You don't have permission to access this loved one's data",
            variant: "destructive",
          });
          localStorage.removeItem("lovedOne");
          navigate("/dashboard");
          return;
        }

        setLovedOne(verifiedLovedOne);
        
        // Get training examples
        try {
          const stored = localStorage.getItem("trainingExamples");
          console.log('Raw stored examples:', stored);
          
          if (!stored) {
            console.log('No stored examples found');
            setSavedSamples([]);
            return;
          }

          const allStoredExamples = JSON.parse(stored);
          console.log('Parsed stored examples:', allStoredExamples);
          
          const storedExamples = Array.isArray(allStoredExamples[verifiedLovedOne.id]) 
            ? allStoredExamples[verifiedLovedOne.id] 
            : [];
            
          console.log('Examples for loved one', verifiedLovedOne.id, ':', storedExamples);
          setSavedSamples(storedExamples);
        } catch (error) {
          console.error('Error parsing stored examples:', error);
          setSavedSamples([]);
        }
      } catch (error) {
        console.error('Authentication check error:', error);
        toast({
          title: "Authentication Error",
          description: "Please sign in to continue",
          variant: "destructive",
        });
        navigate("/login");
      }
    };

    checkAuthAndLovedOne();
  }, [navigate, toast]);

  const viewCurrentExamples = () => {
    if (!Array.isArray(savedSamples) || savedSamples.length === 0) {
      toast({
        title: "No examples found",
        description: "You haven't added any message examples yet.",
      });
      return;
    }

    toast({
      title: "Current Training Examples",
      description: (
        <div className="mt-2 space-y-2">
          {savedSamples.map((sample, i) => (
            <div key={i} className="p-2 bg-cream-50 rounded text-sm">
              {sample}
            </div>
          ))}
        </div>
      ),
    });
  };

  if (!lovedOne) {
    return null;
  }

  return (
    <div className="min-h-screen bg-cream-50">
      <Navigation />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold text-sage-800 mb-2">
          Training Data
        </h1>
        <p className="text-sage-600 mb-8">
          Add message examples from {lovedOne.name} to improve the conversation experience
        </p>

        <div className="bg-white rounded-lg p-8 shadow-sm">
          <p className="text-gray-600 mb-2">
            To make conversations more authentic, you can provide examples of how {lovedOne.name} used to write. 
            This helps capture their unique writing style, including common phrases, emojis, or expressions they often used.
          </p>
          <p className="text-gray-600 font-bold mb-6">
            The more samples that you add here, the better the conversations become!
          </p>

          {Array.isArray(savedSamples) && savedSamples.length > 0 && (
            <div className="mb-6">
              <h3 className="text-lg font-semibold text-sage-800 mb-3">
                Saved Examples ({savedSamples.length})
              </h3>
              <SavedSamplesList samples={savedSamples} />
            </div>
          )}

          <div className="flex flex-col sm:flex-row gap-4">
            <Button
              size="lg"
              onClick={() => setShowUploadModal(true)}
              className="w-full sm:w-auto"
            >
              Add Message Examples
            </Button>

            <Button
              variant="outline"
              size="lg"
              onClick={viewCurrentExamples}
              className="w-full sm:w-auto"
            >
              <Eye className="mr-2 h-4 w-4" />
              View Current Examples
            </Button>
          </div>
        </div>

        <NextStepButton show={Array.isArray(savedSamples) && savedSamples.length > 0} />

        <UploadModal
          open={showUploadModal}
          onClose={() => {
            setShowUploadModal(false);
            if (lovedOne?.id) {
              // Refresh samples after modal closes
              const stored = localStorage.getItem("trainingExamples") || "{}";
              const allStoredExamples = JSON.parse(stored);
              const storedExamples = Array.isArray(allStoredExamples[lovedOne.id])
                ? allStoredExamples[lovedOne.id]
                : [];
              setSavedSamples(storedExamples);
            }
          }}
        />
      </div>
    </div>
  );
};

export default Training;