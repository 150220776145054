import { useEffect, useRef } from "react";
import { supabase } from "@/integrations/supabase/client";
import { Message, SupabaseMessage } from "@/types/messages";
import { formatSupabaseMessage } from "@/utils/messageUtils";

export const useMessageSubscription = (
  lovedOneId: number | null,
  onNewMessage: (message: Message) => void
) => {
  const subscriptionRef = useRef<ReturnType<typeof supabase.channel> | null>(null);

  useEffect(() => {
    if (!lovedOneId) return;

    // Clean up existing subscription if any
    if (subscriptionRef.current) {
      console.log('Cleaning up existing subscription before creating new one');
      supabase.removeChannel(subscriptionRef.current);
      subscriptionRef.current = null;
    }

    console.log('Setting up NEW message subscription for loved one:', lovedOneId);

    const subscription = supabase
      .channel(`messages_channel_${lovedOneId}`) // Make channel name unique per loved one
      .on(
        'postgres_changes',
        {
          event: 'INSERT',
          schema: 'public',
          table: 'messages',
          filter: `conversation_id=eq.${lovedOneId}`
        },
        (payload) => {
          console.log('New message received from subscription:', payload);
          const newMessage = formatSupabaseMessage(payload.new as SupabaseMessage);
          onNewMessage(newMessage);
        }
      )
      .subscribe((status) => {
        console.log(`Subscription status for loved one ${lovedOneId}:`, status);
      });

    subscriptionRef.current = subscription;

    return () => {
      console.log('Cleaning up message subscription for loved one:', lovedOneId);
      if (subscriptionRef.current) {
        supabase.removeChannel(subscriptionRef.current);
        subscriptionRef.current = null;
      }
    };
  }, [lovedOneId, onNewMessage]);
};