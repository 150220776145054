import { useToast } from "@/components/ui/use-toast";
import { supabase } from "@/integrations/supabase/client";
import { PartialMemory } from "@/types/memory";

export const useMemoryValidation = () => {
  const { toast } = useToast();

  const validateMemory = async (memory: PartialMemory) => {
    if (!memory.title || !memory.description) {
      toast({
        title: "Please fill in all fields",
        description: "Both title and description are required",
      });
      return false;
    }
    return true;
  };

  const validateLovedOne = async (userId: string, lovedOneId: string | number) => {
    console.log('Validating loved one:', { userId, lovedOneId });
    
    const numericLovedOneId = typeof lovedOneId === 'string' ? parseInt(lovedOneId, 10) : lovedOneId;
    
    if (isNaN(numericLovedOneId)) {
      console.error('Invalid loved one ID format:', lovedOneId);
      toast({
        title: "Error",
        description: "Invalid loved one ID format",
        variant: "destructive",
      });
      return null;
    }

    const { data: verifiedLovedOne, error: verifyError } = await supabase
      .from('loved_ones')
      .select('id, name')
      .eq('id', numericLovedOneId)
      .eq('user_id', userId)
      .maybeSingle();

    if (verifyError) {
      console.error('Error verifying loved one:', verifyError);
      toast({
        title: "Error",
        description: "Could not verify loved one. Please try selecting them again.",
        variant: "destructive",
      });
      return null;
    }

    if (!verifiedLovedOne) {
      console.error('No loved one found or unauthorized access:', { numericLovedOneId, userId });
      toast({
        title: "Error",
        description: "Could not verify loved one. Please try selecting them again.",
        variant: "destructive",
      });
      return null;
    }

    console.log('Loved one verified successfully:', verifiedLovedOne);
    return verifiedLovedOne;
  };

  return {
    validateMemory,
    validateLovedOne
  };
};