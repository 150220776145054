import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/components/ui/use-toast";
import { Link, useNavigate } from "react-router-dom";

const signUpSchema = z.object({
  email: z.string().email("Please enter a valid email address"),
  firstName: z.string().min(1, "First name is required"),
  lastName: z.string().min(1, "Last name is required"),
  password: z
    .string()
    .min(8, "Password must be at least 8 characters")
    .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
    .regex(/[a-z]/, "Password must contain at least one lowercase letter")
    .regex(/[0-9]/, "Password must contain at least one number"),
});

type SignUpFormProps = {
  onNext: (data: z.infer<typeof signUpSchema>) => void;
};

export const SignUpForm = ({ onNext }: SignUpFormProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const form = useForm({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
    },
  });

  const handleSubmit = async (data: z.infer<typeof signUpSchema>) => {
    console.log("Starting user sign up process with data:", { 
      email: data.email, 
      firstName: data.firstName, 
      lastName: data.lastName 
    });
    
    try {
      // First, test the connection
      const { error: connectionError } = await supabase.auth.getSession();
      if (connectionError) {
        console.error("Connection test failed:", connectionError);
        toast({
          variant: "destructive",
          title: "Connection Error",
          description: "Unable to connect to the service. Please try again later.",
        });
        return;
      }

      // Proceed with signup
      const { data: authData, error } = await supabase.auth.signUp({
        email: data.email,
        password: data.password,
        options: {
          data: {
            first_name: data.firstName,
            last_name: data.lastName,
          },
          emailRedirectTo: `${window.location.origin}/dashboard`
        }
      });

      if (error) {
        console.error("Sign up error:", error);
        
        if (error.message.includes("already registered") || error.message.includes("already exists")) {
          toast({
            variant: "destructive",
            title: "Account already exists",
            description: (
              <div className="space-y-2">
                <p>This email is already registered.</p>
                <Button 
                  variant="outline" 
                  onClick={() => navigate('/login')}
                  className="w-full"
                >
                  Go to Login
                </Button>
              </div>
            ),
          });
          return;
        }
        
        toast({
          variant: "destructive",
          title: "Sign up failed",
          description: error.message,
        });
        return;
      }

      console.log("Sign up successful:", authData);
      
      if (authData?.user) {
        // Send welcome email
        try {
          const response = await fetch(`${import.meta.env.VITE_SUPABASE_URL}/functions/v1/send-welcome-email`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${import.meta.env.VITE_SUPABASE_ANON_KEY}`,
            },
            body: JSON.stringify({
              to: data.email,
              firstName: data.firstName,
            }),
          });

          if (!response.ok) {
            console.error('Failed to send welcome email:', await response.text());
          }
        } catch (emailError) {
          console.error('Error sending welcome email:', emailError);
        }

        console.log("User created successfully:", authData.user);
        toast({
          title: "Account created successfully",
          description: "Welcome to our community!",
        });
        
        // Move to next step first
        onNext(data);
        
        // Set flag for new user in localStorage after moving to next step
        localStorage.setItem("isNewUser", "true");
      } else {
        console.log("No user data returned from signup");
        toast({
          variant: "destructive",
          title: "Sign up error",
          description: "Something went wrong. Please try again.",
        });
      }
    } catch (error) {
      console.error("Unexpected error during sign up:", error);
      toast({
        variant: "destructive",
        title: "Sign up failed",
        description: "An unexpected error occurred. Please try again.",
      });
    }
  };

  return (
    <div className="space-y-6">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input placeholder="Enter your email" type="email" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter your first name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input placeholder="Enter your last name" {...field} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="password"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Password</FormLabel>
                <FormControl>
                  <Input 
                    type="password" 
                    placeholder="Create a password" 
                    {...field} 
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full">Continue</Button>
          <p className="text-sm text-gray-600 text-center mt-4">
            By signing up, you are agreeing to our{" "}
            <Link to="/terms" className="text-primary hover:underline">
              Terms and Conditions
            </Link>{" "}
            and{" "}
            <Link to="/privacy" className="text-primary hover:underline">
              Privacy Policy
            </Link>
          </p>
        </form>
      </Form>
    </div>
  );
};