type StepIndicatorProps = {
  currentStep: number;
  totalSteps: number;
};

export const StepIndicator = ({ currentStep, totalSteps }: StepIndicatorProps) => {
  return (
    <div className="flex justify-center space-x-2 mb-6">
      {Array.from({ length: totalSteps }).map((_, index) => (
        <div
          key={index}
          className={`h-2 w-2 rounded-full transition-all duration-300 ${
            index <= currentStep ? "bg-sage-400" : "bg-sage-200"
          }`}
        />
      ))}
    </div>
  );
};