import { Menu } from 'lucide-react';
import { Button } from "@/components/ui/button";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/components/ui/sheet";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useNavigate } from "react-router-dom";
import { supabase } from "@/integrations/supabase/client";
import { useEffect, useState } from "react";

// Split out the navigation items into a separate component
const NavigationItems = ({ 
  isAuthenticated, 
  navigate, 
  setIsMobileMenuOpen,
  hasTrainingSamples 
}: { 
  isAuthenticated: boolean;
  navigate: (path: string) => void;
  setIsMobileMenuOpen: (open: boolean) => void;
  hasTrainingSamples: boolean;
}) => {
  const MessagesLink = () => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={`${navigationMenuTriggerStyle()} ${!hasTrainingSamples ? 'opacity-50 cursor-not-allowed' : ''}`}>
            Messages
          </div>
        </TooltipTrigger>
        {!hasTrainingSamples && (
          <TooltipContent>
            <p>Add samples to training first</p>
          </TooltipContent>
        )}
      </Tooltip>
    </TooltipProvider>
  );

  return (
    <>
      {isAuthenticated && (
        <NavigationMenuItem>
          <NavigationMenuLink
            className={navigationMenuTriggerStyle()}
            onClick={() => {
              navigate("/loved-ones-options");
              setIsMobileMenuOpen(false);
            }}
          >
            Dashboard
          </NavigationMenuLink>
        </NavigationMenuItem>
      )}
      {isAuthenticated && (
        <>
          <NavigationMenuItem>
            <NavigationMenuLink
              className={navigationMenuTriggerStyle()}
              onClick={() => {
                navigate("/dashboard");
                setIsMobileMenuOpen(false);
              }}
            >
              Loved Ones
            </NavigationMenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem>
            <NavigationMenuLink
              className={navigationMenuTriggerStyle()}
              onClick={() => {
                navigate("/vault");
                setIsMobileMenuOpen(false);
              }}
            >
              Memory Vault
            </NavigationMenuLink>
          </NavigationMenuItem>
          <NavigationMenuItem>
            {hasTrainingSamples ? (
              <NavigationMenuLink
                className={navigationMenuTriggerStyle()}
                onClick={() => {
                  navigate("/messages");
                  setIsMobileMenuOpen(false);
                }}
              >
                Messages
              </NavigationMenuLink>
            ) : (
              <MessagesLink />
            )}
          </NavigationMenuItem>
          <NavigationMenuItem>
            <NavigationMenuLink
              className={navigationMenuTriggerStyle()}
              onClick={() => {
                navigate("/training");
                setIsMobileMenuOpen(false);
              }}
            >
              Training
            </NavigationMenuLink>
          </NavigationMenuItem>
        </>
      )}
    </>
  );
};

// Split out the auth buttons into a separate component
const AuthButtons = ({ 
  isAuthenticated, 
  navigate, 
  handleSignOut,
  setIsMobileMenuOpen 
}: { 
  isAuthenticated: boolean;
  navigate: (path: string) => void;
  handleSignOut: () => Promise<void>;
  setIsMobileMenuOpen: (open: boolean) => void;
}) => (
  <>
    {!isAuthenticated ? (
      <>
        <Button
          variant="ghost"
          onClick={() => {
            navigate("/login");
            setIsMobileMenuOpen(false);
          }}
        >
          Sign In
        </Button>
        <Button
          onClick={() => {
            navigate("/onboarding");
            setIsMobileMenuOpen(false);
          }}
        >
          Get Started
        </Button>
      </>
    ) : (
      <Button
        variant="ghost"
        onClick={() => {
          handleSignOut();
          setIsMobileMenuOpen(false);
        }}
      >
        Sign Out
      </Button>
    )}
  </>
);

export function Navigation() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [hasTrainingSamples, setHasTrainingSamples] = useState(false);

  useEffect(() => {
    const checkAuth = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      setIsAuthenticated(!!session);

      if (session) {
        // Check if there are any training samples for the current loved one
        const lovedOne = JSON.parse(localStorage.getItem("lovedOne") || "null");
        if (lovedOne?.id) {
          const { data: samples } = await supabase
            .from('training_samples')
            .select('id')
            .eq('loved_one_id', lovedOne.id)
            .limit(1);
          
          setHasTrainingSamples(samples && samples.length > 0);
        }
      }
    };

    checkAuth();

    // Subscribe to auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setIsAuthenticated(!!session);
    });

    // Subscribe to training_samples changes
    const trainingSamplesSubscription = supabase
      .channel('training_samples_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'training_samples'
        },
        async () => {
          // Re-check training samples when changes occur
          const lovedOne = JSON.parse(localStorage.getItem("lovedOne") || "null");
          if (lovedOne?.id) {
            const { data: samples } = await supabase
              .from('training_samples')
              .select('id')
              .eq('loved_one_id', lovedOne.id)
              .limit(1);
            
            setHasTrainingSamples(samples && samples.length > 0);
          }
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
      trainingSamplesSubscription.unsubscribe();
    };
  }, []);

  const handleSignOut = async () => {
    await supabase.auth.signOut();
    navigate("/");
  };

  return (
    <div className="w-full border-b bg-white/95 backdrop-blur supports-[backdrop-filter]:bg-white/60">
      <div className="container flex h-14 max-w-screen-2xl items-center">
        <div 
          className="text-2xl font-bold text-lavender-800 mr-6 cursor-pointer px-2 py-1 rounded-md bg-lavender-50 flex items-center"
          onClick={() => navigate(isAuthenticated ? "/loved-ones-options" : "/")}
        >
          MemorySpace.co
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex flex-1">
          <NavigationMenu>
            <NavigationMenuList>
              <NavigationItems 
                isAuthenticated={isAuthenticated}
                navigate={navigate}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
                hasTrainingSamples={hasTrainingSamples}
              />
            </NavigationMenuList>
          </NavigationMenu>
          <div className="ml-auto flex items-center space-x-4">
            <AuthButtons 
              isAuthenticated={isAuthenticated}
              navigate={navigate}
              handleSignOut={handleSignOut}
              setIsMobileMenuOpen={setIsMobileMenuOpen}
            />
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="flex md:hidden flex-1 justify-end">
          <Sheet open={isMobileMenuOpen} onOpenChange={setIsMobileMenuOpen}>
            <SheetTrigger asChild>
              <Button variant="ghost" size="icon">
                <Menu className="h-5 w-5" />
              </Button>
            </SheetTrigger>
            <SheetContent side="right" className="w-[80vw] sm:w-[385px]">
              <div className="flex flex-col space-y-4 py-4">
                <NavigationMenu className="flex-col items-start">
                  <NavigationMenuList className="flex-col items-start space-y-2">
                    <NavigationItems 
                      isAuthenticated={isAuthenticated}
                      navigate={navigate}
                      setIsMobileMenuOpen={setIsMobileMenuOpen}
                      hasTrainingSamples={hasTrainingSamples}
                    />
                  </NavigationMenuList>
                </NavigationMenu>
                <div className="flex flex-col space-y-2">
                  <AuthButtons 
                    isAuthenticated={isAuthenticated}
                    navigate={navigate}
                    handleSignOut={handleSignOut}
                    setIsMobileMenuOpen={setIsMobileMenuOpen}
                  />
                </div>
              </div>
            </SheetContent>
          </Sheet>
        </div>
      </div>
    </div>
  );
}