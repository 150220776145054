import React, { useState } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Send } from "lucide-react";

interface MessageInputProps {
  onSend: (message: string) => void;
  disabled?: boolean;
}

const MessageInput = ({ onSend, disabled }: MessageInputProps) => {
  const [newMessage, setNewMessage] = useState("");

  const handleSend = () => {
    onSend(newMessage);
    setNewMessage("");
  };

  return (
    <div className="p-4 bg-[#F2F2F7] border-t border-gray-200">
      <div className="flex gap-2">
        <Input
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="iMessage"
          className="flex-1 rounded-full bg-white border-0 focus-visible:ring-1 focus-visible:ring-[#007AFF]"
          onKeyPress={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSend();
            }
          }}
          disabled={disabled}
        />
        <Button 
          onClick={handleSend} 
          className="rounded-full bg-[#007AFF] hover:bg-[#007AFF]/90 h-10 w-10 p-0"
          disabled={disabled}
        >
          <Send className="h-5 w-5" />
        </Button>
      </div>
    </div>
  );
};

export default MessageInput;