import React from 'react';
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";

interface ImagePreviewProps {
  photoPreview: string;
  onRemovePhoto: () => void;
}

const ImagePreview = ({ photoPreview, onRemovePhoto }: ImagePreviewProps) => {
  if (!photoPreview) return null;

  return (
    <div className="relative">
      <Dialog>
        <DialogTrigger asChild>
          <div className="cursor-pointer">
            <img
              src={photoPreview}
              alt="Memory preview"
              className="w-full max-h-[400px] object-contain rounded-lg mb-2"
            />
          </div>
        </DialogTrigger>
        <DialogContent className="max-w-4xl w-[90vw] h-[90vh] flex items-center justify-center">
          <img
            src={photoPreview}
            alt="Memory full view"
            className="max-w-full max-h-full object-contain"
          />
        </DialogContent>
      </Dialog>
      <Button
        variant="outline"
        size="icon"
        className="absolute top-2 right-2"
        onClick={onRemovePhoto}
      >
        <X className="h-4 w-4" />
      </Button>
    </div>
  );
};

export default ImagePreview;